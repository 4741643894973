import React, { useEffect, useState } from "react";

import { SummaryTable } from "../../components/Summary/SummaryTable/SummaryTable";
import { TotalSummary } from "../../components/Summary/TotalSummary/TotalSummary";
import { BackButton } from "../../components/shared/BackButton/BackButton";
import { Button } from "../../components/shared/Button/Button";
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage";
import { Animate } from "../../components/shared/Animate/Animate";
import { useErrorHandler } from "../../lib/hooks/useErrorHandler";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import SummaryTableSkeleton from "../../components/Summary/SummaryTable/SummaryTableSkeleton/SummaryTableSkeleton";
import TotalSummarySkeleton from "../../components/Summary/TotalSummary/TotalSummarySkeleton/TotalSummarySkeleton";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { useSocketContext } from "../../lib/context/SocketContext/SocketContext";
import * as API from "../../api/Api";
import { OrderDetailByUser } from "../../api/Api";

import "./Summary.scss";
import { getTimeLeft } from "../../lib/helpers/getTimeLeft";
import { isEmptyObject } from "../../lib/helpers/isEmptyObject";

interface Props {
  orderDetailsByUser: OrderDetailByUser;
  match: {
    params: {
      id: string;
    };
  };
}
interface IDs {
  orderId: string;
  user_id: string;
}

export const Summary = (props: Props) => {
  const [order, setOrder] = useState<API.Order>();
  const [expireTime, setExpireTime] = useState<string>();
  const [orderExpired, setOrderExpired] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userOrdered, setUserOrdered] = useState<object>();
  const [reFetch, setReFetch] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const authCtx = useAuthContext();
  const errorHandler = useErrorHandler();
  const { socket } = useSocketContext();

  const orderId = props.match.params.id;

  useEffect(() => {
    loadOrder();
  }, [orderId, reFetch]);

  useEffect(() => {
    if (order?.deadline !== undefined) {
      const expireTime = new Date(order.deadline);
      setExpireTime(expireTime?.toLocaleTimeString());
    }
  }, [order]);

  useEffect(() => {
    if (order?.orderDetailsByUser) {
      const user = Object.values(order?.orderDetailsByUser).find(
        (el) => el.full_name === authCtx.user?.full_name
      );
      setUserOrdered(user);

      const timeLeft = getTimeLeft(order?.deadline);
      setOrderExpired(timeLeft.expired);
    }
  }, [order?.orderDetailsByUser]);

  useEffect(() => {
    socket.on("createOrderDetails", (data: API.OrderDetailByUser) => {
      if (Object.values(data)[0].order_id === orderId) {
        setOrder((prev) => {
          return {
            ...prev,
            orderDetailsByUser: {
              ...prev?.orderDetailsByUser,
              ...data
            }
          } as API.Order;
        });
      }
    });
  }, []);

  useEffect(() => {
    socket.on("getOrderSummary", (orderSummary: API.OrderSummarySocket) => {
      setOrder((prev) => {
        return {
          ...prev,
          orderSummary: orderSummary.orderSummary
        } as API.Order;
      });
    });
  }, []);

  socket.on("deleteOrderOfUser", (data: IDs) => {
    if (
      order?.orderDetailsByUser !== undefined &&
      order?.orderDetailsByUser !== null
    ) {
      const asArray = Object.entries(order?.orderDetailsByUser); // Convert `order` to a key/value array
      // Use `filter()` to filter the key/value array
      const orderDetailsUpdated = asArray.filter(
        ([key]) => key !== data.user_id
      );
      const orderDetailsUpdatedAsObj = Object.fromEntries(orderDetailsUpdated); // Convert the key/value array back to an object:
      setOrder({
        ...order,
        orderDetailsByUser: orderDetailsUpdatedAsObj
      } as API.Order);
    }
  });

  const loadOrder = async () => {
    try {
      const order = await API.getOrder(orderId);
      setOrder(order);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (orderId: string) => {
    try {
      setIsDeleting(true);
      await API.deleteYourOrder(orderId);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setReFetch(true);
      setIsDeleting(false);
    }
  };

  let none = "";

  if (isEmptyObject(order?.orderDetailsByUser)) {
    none = "Test__none";
  } else {
    none = "Test";
  }

  return (
    <div className="Summary">
      <Animate animation="bottom-up">
        <>
          <BackButton
            className="Summary__backbutton"
            to={orderExpired ? "" : "/active-list"}
          >
            Back
          </BackButton>
          <ErrorMessage errorData={errorHandler} />
          <div className="Summary__info">
            <p className="Summary__info-title">
              Porosia <span>{order?.orderName}</span> e krijuar nga{" "}
              {order?.orderAuthor} eshte aktive deri ne{" "}
              <span>{expireTime}</span>
            </p>
            {!orderExpired && (
              <Button
                className={`Summary__button ${none}`}
                to={`/order-page/${order?.categoryId}/${orderId}`}
                icon="plus"
              >
                {userOrdered ? "NDRYSHO POROSINË" : "SHTO POROSINË"}
              </Button>
            )}
          </div>
          <div className="Summary__content">
            <HandleLoadingState
              loading={loading}
              placeholder={<SummaryTablePlaceholder />}
            >
              <>
                {order && (
                  <>
                    <SummaryTable
                      categoryId={order.categoryId}
                      orderDetailsByUser={order.orderDetailsByUser}
                      onDelete={handleDelete}
                      orderId={order.orderId}
                      orderExpired={orderExpired}
                      isDeleting={isDeleting}
                    />
                    <TotalSummary
                      orderDetailsByUser={order.orderDetailsByUser}
                      orderSummary={order.orderSummary}
                    />
                  </>
                )}
              </>
            </HandleLoadingState>
          </div>
        </>
      </Animate>
    </div>
  );
};

function SummaryTablePlaceholder() {
  return (
    <>
      <SummaryTableSkeleton />
      <TotalSummarySkeleton />
    </>
  );
}
