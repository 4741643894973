import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface PrivacyField {
  password: string
  passwordConfirmation: string
}
interface usePrivacyFormOption {
  initialValues?: PrivacyField
  onSubmit: (
    value: PrivacyField,
    formikHelpers: FormikHelpers<PrivacyField>
  ) => Promise<any>
}

export const usePrivacyFormik = (options: usePrivacyFormOption) => {
  const PrivacySchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords do not match")
      .required("Password Confirmation is required")
      .min(8, "Password must be inbetween 8 and 30 characters!")
  })

  return useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: PrivacySchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type PrivacyFormik = ReturnType<typeof usePrivacyFormik>
