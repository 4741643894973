import React, { useEffect, useState } from "react"
import { Alert as RAlert, AlertProps } from "reactstrap"

export interface Props extends AlertProps {
  onClose?: () => void
  duration?: number
}

export const Alert = (props: Props) => {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    setVisible(true)
    if (props.duration) {
      let timer = setTimeout(() => {
        props.onClose && props.onClose()
      }, props.duration * 1000)

      return () => clearTimeout(timer)
    }
  }, [props.status])

  if (!visible) return null

  if (props.status === false) return null

  return <RAlert {...props} isOpen={visible} toggle={props.onClose}></RAlert>
}
