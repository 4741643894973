import React, { useCallback, useEffect, useState } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Animate } from "../../components/shared/Animate/Animate";
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage";
import { useErrorHandler } from "../../lib/hooks/useErrorHandler";
import { BoxItemLoader } from "../../components/shared/BoxItem/BoxItemLoader";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { Alert } from "../../components/shared/Alert/Alert";
import { useSocketContext } from "../../lib/context/SocketContext/SocketContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { Order } from "../../components/Order/Order";
import * as API from "../../api/Api";

//styles
import "./ActiveList.scss";

interface Props {}
interface OrderId {
  orderId: string;
}

export const ActiveList = (props: RouteComponentProps<Props>) => {
  const [activeOrders, setActiveOrders] = useState<API.Order[]>([]);
  const [inActiveOrders, setInActiveOrders] = useState<API.Order[]>();

  const [loading, setLoading] = useState<boolean>(true);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteOrderId, setDeleteOrderId] = useState<string>();
  const errorHandler = useErrorHandler();
  const { socket } = useSocketContext();
  const router = useRouter();

  const getInactiveOrders = useCallback(async () => {
    try {
      const allOrders = await API.getAllOrders();
      setInActiveOrders(allOrders.inActiveOrders);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getInactiveOrders();
  }, [getInactiveOrders]);

  useEffect(() => {
    socket.on("createOrder", (data: API.Order) => {
      setActiveOrders((prevState) => [...prevState, data]);
    });
  }, []);
  useEffect(() => {
    socket.on("deleteOrder", (data: OrderId) => {
      setActiveOrders((prevState) =>
        prevState.filter((order) => order.id !== data.orderId)
      );
    });
  }, []);

  const getAllOrders = useCallback(async () => {
    try {
      const allOrders = await API.getAllOrders();
      setActiveOrders(allOrders.activeOrders);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const onDeleteOrder = async (orderId: string) => {
    try {
      setDeleting(true);
      setDeleteOrderId(orderId);
      await API.deleteOrder(orderId);
      setActiveOrders((prevState) =>
        prevState.filter((order) => order.id !== orderId)
      );
      setDeleting(false);
    } catch (e: any) {
      errorHandler.handleError(e);
    }
  };

  return (
    <div className="ActiveList">
      <Animate animation="bottom-up">
        <>
          <div className="ActiveList__header"></div>
          {errorHandler && (
            <ErrorMessage errorData={errorHandler}></ErrorMessage>
          )}

          <div className="ActiveList__wrapper">
            <div className="ActiveList__headerText">Porositë aktive</div>
            <div className="ActiveList__container">
              <HandleLoadingState
                loading={loading}
                placeholder={<ActiveListPlaceholder />}
              >
                <>
                  {!activeOrders || activeOrders.length === 0 ? (
                    <Alert className="ActiveList__alert" color="info">
                      Nuk ka porosi aktive ende!{" "}
                      <NavLink to="./add-order" className="ActiveList__nav">
                        {" "}
                        Krijo porosi?{" "}
                      </NavLink>
                    </Alert>
                  ) : (
                    activeOrders.map((item) => {
                      return (
                        <Order
                          key={`Order-${item.id}`}
                          id={item.id}
                          category={item.category}
                          user={item.name}
                          orderAuthor={item.order_author}
                          userList={item.order_customers}
                          expireTime={item.deadline}
                          onClick={() => {
                            props.history.push(`/summary/${item.id}`);
                          }}
                          deleteOrderId={deleteOrderId}
                          deleting={deleting}
                          handleDelete={onDeleteOrder}
                        />
                      );
                    })
                  )}
                </>
              </HandleLoadingState>
            </div>
            <>
              <div className="Order__expired">
                <div className="ActiveList__expiredText">Të porositura</div>
                {!inActiveOrders || inActiveOrders.length === 0 ? (
                  <Alert className="ActiveList__alert" color="info">
                    Nuk ka porosi ne arkivë!{" "}
                  </Alert>
                ) : (
                  inActiveOrders.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          router.history.push(`/summary/${item.id}`);
                        }}
                        className="Ordered"
                      >
                        <div className="Ordered__location">
                          Puza ne {item.category}
                        </div>
                        <div className="Ordered__info">
                          <div className="Ordered__info--text">
                            Vendi: {item.category}
                          </div>
                          <div className="Ordered__info--text">
                            Tel: 044 114 224
                          </div>
                          <div className="Ordered__info--text">
                            Krijuesi: {item.order_author}
                          </div>
                          <div className="Ordered__info--text">
                            <div className="Ordered__info--condition">
                              expired
                            </div>{" "}
                            <div className="Order__expireTime-grey"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          </div>
        </>
      </Animate>
    </div>
  );
};

function ActiveListPlaceholder() {
  return (
    <>
      <BoxItemLoader
        width={369}
        height={369}
        title={true}
        itemSize="25"
        category={true}
      />
      <BoxItemLoader
        width={369}
        height={369}
        title={true}
        itemSize="25"
        category={true}
      />
      <BoxItemLoader
        width={369}
        height={369}
        title={true}
        itemSize="25"
        category={true}
      />
    </>
  );
}
