import React from "react";
import { SelectedItem } from "../SelectedItem/SelectedItem";
import { TotalCash as Cash } from "../TotalCash/TotalCash";
import { Button } from "../../components/shared/Button/Button";
import { useConfirmation } from "../../lib/context/ConfirmationContext/ConfirmationContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { useOrderFormik } from "./lib/useOrderFormik";
import { useEffect } from "react";
import { useErrorHandler } from "../../lib/hooks/useErrorHandler";
import { ErrorMessage } from "../shared/ErrorMessage/ErrorMessage";
import * as API from "../../api/Api";

import "./Receipt.scss";

interface OrderItem {
  id: string;
  product_name?: string;
  price: string;
  item?: string;
  quantity?: number;
}
interface Props {
  orders: OrderItem[];
  orderId: string;
  totalPrice: number;
  deleteSelectedItem: (id: string) => void;
  changeQuantity: (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: string
  ) => void;
}

export const Receipt = (props: Props) => {
  const errorHandler = useErrorHandler();
  const router = useRouter();

  const formik = useOrderFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.createOrderItem({
          orderId: props.orderId,
          products: values.products,
          payedAmount: values.payedAmount,
          total: values.total
        });
        router.history.push(`/summary/${props.orderId}`);
        formikHelpers.resetForm();
      } catch (e: any) {
        errorHandler.handleError(e);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue("total", props.totalPrice.toFixed(2));
  }, [props.totalPrice]);

  useEffect(() => {
    const options = props.orders.map((product) => {
      return {
        product_id: product.id,
        price: product.price,
        product_name: product.product_name,
        quantity: product.quantity
      };
    });
    formik.setFieldValue("products", options);
  }, [props.orders]);

  //change cash input state

  const payment_amount = formik.values.payedAmount;

  const change = payment_amount - props.totalPrice || 0;

  let isButtonDisabled = true;

  const confirmation = useConfirmation();

  return (
    <form className="Receipt__form" onSubmit={formik.handleSubmit}>
      <div className="Receipt">
        <div className="Receipt__body">
          <div className="Receipt__products">
            <p className="Receipt__title">POROSIA</p>
            <ol className="Receipt__items" key={3}>
              {props.orders.map((order) => {
                isButtonDisabled = !(
                  formik.values.payedAmount >= props.totalPrice
                );
                return (
                  <li key={`Order-${order.id}`}>
                    <SelectedItem
                      id={order.id}
                      item={order.product_name}
                      quantity={order.quantity}
                      price={order.price}
                      deleteSelectedItem={props.deleteSelectedItem}
                      changeQuantity={props.changeQuantity}
                    />
                  </li>
                );
              })}
            </ol>
          </div>
          <div className="Receipt__total">
            <div className="Receipt__totalTitle">
              <p>Para të gatshme</p>
            </div>
            <Cash
              max={100}
              onValueChange={formik.handleChange}
              values={formik.values}
            />
            <span className="Receipt__input-error">
              {formik.errors.payedAmount}
            </span>
            <div className="Receipt__change">
              <p>Kusur</p>
              <p>
                <i>{Math.max(0, change).toFixed(2)} €</i>
              </p>
            </div>
            <div className="Receipt__price">
              <p>Totali i pagesës</p>

              <p>
                <i>{props.totalPrice.toFixed(2)} €</i>
              </p>
            </div>

            <ErrorMessage
              errorData={errorHandler}
              onClose={errorHandler.reset}
            />
            <Button
              className="Button Receipt__button"
              type="button"
              disabled={isButtonDisabled}
              onClick={() => {
                confirmation
                  .confirm("Përfundo porosinë?")
                  .then(formik.submitForm);
              }}
            >
              PËRFUNDO POROSINË
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
