import React, { useEffect, useState } from "react"

import "./Image.scss"

interface ImageProps {
  file?: string | File
  alt?: string
  className?: string
}
const defaultPath = require("../../../assets/images/KutiaLogo.svg")

const Image = (props: ImageProps) => {
  const { file } = props

  const [imgUpload, setImgUpload] = useState<string | ArrayBuffer | null>("")
  const addDefaultSrc = (e: any) => {
    e.target.src = defaultPath
  }
  useEffect(() => {
    setImgUpload(null)
    if (file === null) return
    if (typeof file === "object") {
      let reader = new FileReader()
      reader.readAsDataURL(file as any)
      reader.onload = () => {
        setImgUpload(reader.result)
      }
      return
    }
    setImgUpload(props.file as string)
  }, [props.file, file])

  return (
    <img
      className={`Image ${props.className}`}
      src={imgUpload ? (imgUpload as any) : defaultPath}
      alt={props.alt || "Image Placeholder"}
      onError={addDefaultSrc}
    />
  )
}
export default Image
