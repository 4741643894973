import { useState, useEffect } from "react"
import { getTimeLeft } from "../helpers/getTimeLeft"
export const useTimeLeft = (expireTime: string) => {
  const [value, setValue] = useState(getTimeLeft(expireTime))

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(getTimeLeft(expireTime))
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [expireTime])

  return value
}
