import React, { useEffect, useState } from "react";
import { Table, Popover } from "reactstrap";
import {
  OrderDetailByUser,
  OrderSummary,
  OrderSummaryProduct
} from "../../../api/Order";

import "./TotalSummary.scss";
interface Props {
  orderSummary: OrderSummary;
  orderDetailsByUser: OrderDetailByUser;
}
export const TotalSummary = (props: Props) => {
  const { orderSummary } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen((prevState) => !prevState);

  const handleCopy = () => {
    toggle();
    const copy = orderSummary.products.map(
      (item: any) => `
${item.quantity}x ${item.name}`
    );
    navigator.clipboard.writeText(`${copy}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setPopoverOpen(false);
    }, 2500);
  }, [popoverOpen]);

  if (!orderSummary) {
    return <div></div>;
  }

  let none = "";

  if (Object.keys(props.orderDetailsByUser).length === 0) {
    none = "Test__none";
  } else {
    none = "Test";
  }

  return (
    <div className="TotalSummary">
      <div className={`${none}`}>
        <Table>
          <thead>
            <tr>
              <th>PËRMBLEDHJA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {orderSummary.products &&
                  orderSummary.products.map(
                    (product: OrderSummaryProduct, index: number) => {
                      return (
                        <ul>
                          <>
                            <li key={index}>
                              {product.quantity}x {product.name}
                            </li>
                          </>
                        </ul>
                      );
                    }
                  )}
              </td>
            </tr>
            <button
              type="button"
              onClick={handleCopy}
              className="table__copy"
              id="PopoverFocus"
            />
            <Popover
              isOpen={popoverOpen}
              target="PopoverFocus"
              toggle={toggle}
              style={{
                color: "white",
                background: "#121d23",
                padding: "3px 7px",
                fontSize: "15px"
              }}
            >
              Copied!
            </Popover>
          </tbody>
          <tfoot>
            <tr>
              <td className="totalCost">
                Totali:
                <div className="totalCost__text">
                  {parseFloat(orderSummary.total).toFixed(2)} €{" "}
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};
