import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { ForgotPasswordForm } from "../../components/User/ForgotPasswordForm/ForgotPasswordForm"
import { NavLink } from "react-router-dom"
import { Animate } from "../../components/shared/Animate/Animate"
import { FormBox } from "../../components/FormBox/FormBox"
//styles
import "./ForgotPassword.scss"

interface Props {}

export const ForgotPassword = (props: RouteComponentProps<Props>) => {
  return (
    <Animate animation="bottom-up">
      <div className="ForgotPassword centered-content">
        <FormBox>
          <div className="ForgotPassword__Form">
            <ForgotPasswordForm />
            <NavLink className="ForgotPassword__nav" to="/login">
              back to login
            </NavLink>
          </div>
        </FormBox>
      </div>
    </Animate>
  )
}
