import Axios from "axios";
import React, { useState, useEffect } from "react";

import * as API from "../../../api/Api";

import { AuthContextType, AuthContext } from "./AuthContext";
import { LoginRegisterResponse } from "../../../api/Api";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

const LOCAL_STORAGE_KEY = "PROJECT_KUTIA_POROSIA";

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [user, setUser] = useState<API.User | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<
    string | { [key: string]: string } | undefined
  >(undefined);

  useEffect(() => {
    checkAuthentication();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    const authToken = localStorage.getItem(LOCAL_STORAGE_KEY);
    Axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    if (!authToken) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      return;
    }
    try {
      const res = await API.getUserDetails();
      const user = res.user[0];
      setUser({
        full_name: user.full_name,
        email: user.email,
        username: user.username,
        password: user.password,
        role: user.role
      });

      if (!res.user) {
        return;
      }
    } catch (err: any) {
      setError(err.toString());
    } finally {
      setLoading(false);
    }
  };
  const updateUser = async (user: API.UpdateUserResponse) => {
    setUser({
      full_name: user.updatedUserProfile.full_name,
      email: user.updatedUserProfile.email,
      username: user.updatedUserProfile.username,
      password: user.updatedUserProfile.password,
      role: user.updatedUserProfile.role
    });
  };
  const login = async (credentials: LoginRegisterResponse) => {
    Axios.defaults.headers.common.Authorization = `Bearer ${credentials.token}`;
    setUser({
      // user_id: user?.user_id,
      email: credentials.user.email,
      full_name: credentials.user.full_name,
      username: credentials.user.username,
      role: credentials.user.role,
      password: credentials.user.password
    });

    localStorage.setItem(LOCAL_STORAGE_KEY, credentials.token);
  };

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setUser(undefined);
  };

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    error: error,
    user: user,
    logout: logout,
    login: login,
    checkAuthentication,
    updateUser
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
