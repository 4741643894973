import { apiRequest } from "./Api"

export interface Product {
  category_id: string
  product_name: string
  price: string
  id: string
  media: string
}

export interface ProductsResponse {
  product: Product[]
}
export const getProductsByCategory = async (categoryId: string) =>
  apiRequest<string, ProductsResponse>(
    "get",
    `dashboard/product/get-products-by-category?categoryId=${categoryId}`
  )
export const createProduct = async (data: FormData) =>
  apiRequest<FormData, { product: Product }>(
    "post",
    "dashboard/product/create",
    data
  )
