import React from "react"
import cs from "classnames"
import { useHistory } from "react-router-dom"
//components
import { Icon } from "../Icon/Icon"
//styles
import "./BackButton.scss"
import { useRouter } from "../../../lib/hooks/useRouter"

interface Props {
  className?: string
  children?: string
  to: string
}
export const BackButton = (props: Props) => {
  let history = useHistory()
  const router = useRouter()
  const classNames = cs("BackButton", props.className)
  return (
    <button
      {...props}
      type="button"
      className={classNames}
      onClick={() => router.history.push(props.to)}
    >
      <Icon className="arrow" icon="backarrow" /> {props.children}
    </button>
  )
}
