import React from "react"
import { Button } from "../../shared/Button/Button"
import { useLoginFormik } from "./lib/useLoginFormik"
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext"
import { useRouter } from "../../../lib/hooks/useRouter"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { Input } from "../../shared/Input/Input"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"

import * as API from "../../../api/Api"

//styles
import "./LoginForm.scss"

export const LoginForm = () => {
  const authCtx = useAuthContext()
  const router = useRouter()
  const errorHandler = useErrorHandler()
  const formik = useLoginFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.login(values)
        authCtx.login(res)
        formikHelpers.resetForm()
        router.history.push("/")
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })

  return (
    <div className="LoginForm">
      <form onSubmit={formik.handleSubmit}>
        <h5 className="LoginForm__title">Log In</h5>
        <Input
          placeholder="Write your email"
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.email && formik.touched.email}
          isInvalid={!!formik.errors.email && formik.touched.email}
          error={formik.errors.email}
        />
        <Input
          placeholder="Write your password"
          type="password"
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.password && formik.touched.password}
          isInvalid={!!formik.errors.password && formik.touched.password}
          error={formik.errors.password}
        />

        <ErrorMessage
          duration={2}
          errorData={errorHandler}
          onClose={errorHandler.reset}
        />

        <Button
          type="submit"
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          {formik.isSubmitting ? "LOGGING IN..." : "LOG IN"}
        </Button>
      </form>
    </div>
  )
}
