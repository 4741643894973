/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react"
import { ReactComponent as LogoSvg } from "../../assets/icons/logo.svg"
import { Icon } from "../../components/shared/Icon/Icon"
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext"
import { useRouter } from "../../lib/hooks/useRouter"

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap"

import "./Header.scss"

const Header = () => {
  const authContext = useAuthContext()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const router = useRouter()

  return (
    <div className="Header">
      <LogoSvg
        className="Header__logo"
        onClick={() => router.history.push("/main-page")}
      />
      <Dropdown
        className="Header__dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle className="Header__dropdown-toggle">
          <Icon className="Header__userLogo" icon="profile" />
          <div className="Header__userInfo">
            {authContext.user?.full_name !== undefined
              ? `${authContext.user?.full_name}`
              : `${authContext.user?.email}`}
          </div>
        </DropdownToggle>
        <DropdownMenu className="Header__dropdown-menu" right>
          <DropdownItem
            className="Header__dropdown-item"
            onClick={() => router.history.push("/my-profile")}
          >
            Profile
          </DropdownItem>
          <DropdownItem
            className="Header__dropdown-item"
            onClick={authContext.logout}
          >
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default Header
