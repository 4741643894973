import React from "react";
import { Icon } from "../Icon/Icon";
import { IconDefinition } from "../Icon/Icon.generated";
import { useRouter } from "../../../lib/hooks/useRouter";
import Image from "../Image/Image";
//styles
import cs from "classnames";
import "./BoxItem.scss";
export type BoxItemType = "M" | "L" | "S";

interface Props {
  id?: string;
  type?: BoxItemType;
  isProduct?: boolean;
  icon?: IconDefinition;
  picturePath?: string;
  itemDescription: string;
  productPrice?: string;
  path?: string;
  selected?: string;
  isActive?: boolean;
  isProductSelected?: boolean;
  invalid?: boolean;
  isStatic?: boolean;
  onClick?: () => void;
}

export const BoxItem = (props: Props) => {
  // checks which className to set according to given type from props

  const router = useRouter();

  let boxClasses = cs(
    "BoxItem",
    props.type === "M"
      ? "BoxItem--m"
      : props.type === "L"
      ? "BoxItem--l"
      : "BoxItem--s",
    props.isActive || props.selected === props.itemDescription
      ? "BoxItem--selectedAdd"
      : "",
    props.isProductSelected ? "BoxItem--selected" : ""
  );

  const deactivated = props.id && { "BoxItem--deactive": !props.isActive };
  return (
    <div
      className={cs(boxClasses, deactivated)}
      onClick={() => {
        props.path && router.history.push(props.path);
        props.onClick && props.onClick();
      }}
    >
      <div
        className={
          props.isProduct ? "BoxItem__productIcon" : "BoxItem__itemIcon"
        }
      >
        {" "}
        {!props.isStatic ? (
          props.isProduct ? (
            <Image file={props.picturePath} alt={props.picturePath} />
          ) : (
            <Image className="MarketPic" file={props.picturePath} />
          )
        ) : (
          <Icon icon={props.icon} />
        )}
      </div>
      <div
        className={
          props.isProduct
            ? "BoxItem__productdescription"
            : "BoxItem__itemdescription"
        }
      >
        <div className="BoxItem__title">{props.itemDescription}</div>
        {props.isProduct && <p>{props.productPrice + " €"}</p>}
      </div>
    </div>
  );
};
