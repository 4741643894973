import React from "react"
import ContentLoader from "react-content-loader"

import "./BoxItemLoader.scss"

interface Props {
  width?: number
  height?: number
  title?: boolean
  category?: boolean
  itemSize?: string
  desc?: boolean
}

export const BoxItemLoader = (props: Props) => (
  <ContentLoader
    speed={2}
    width={props.width}
    height={props.height}
    viewBox="0 0 167 176"
    backgroundColor="#4f5664"
    className="BoxItemLoader"
    foregroundColor="#4b5362"
  >
    {props.title ? (
      <rect x="120" y="10" rx="3" ry="3" width="30" height="6" />
    ) : (
      ""
    )}
    {props.title ? (
      <rect x="12" y="10" rx="3" ry="3" width="50" height="6" />
    ) : (
      ""
    )}
    {props.desc ? (
      <rect x="105" y="160" rx="3" ry="3" width="30" height="6" />
    ) : (
      ""
    )}
    {props.desc ? (
      <rect x="12" y="160" rx="3" ry="3" width="50" height="6" />
    ) : (
      ""
    )}

    <circle cx="83" cy="88" r={props.itemSize} />
    {props.category ? (
      <rect x="60" y="125" rx="3" ry="3" width="46" height="8" />
    ) : (
      ""
    )}
  </ContentLoader>
)
