/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { AppRoutes, RouteType } from "./Routes"
import { Redirect, Switch } from "react-router-dom"
import { RestrictedRoute } from "./RestrictedRoute"
import { RestrictedHeader } from "../partials/RestrictedHeader/RestrictedHeader"

const RestrictedRoutes = () => {
  const restrictedRoutes = AppRoutes.filter(
    (appRoutes) => appRoutes.type === RouteType.RESTRICTED
  )
  return (
    <div className="bg-theme">
      <RestrictedHeader />
      <Switch>
        {restrictedRoutes.map((route, index) => {
          return (
            <RestrictedRoute
              {...route}
              path={`/${route.path}`}
              key={`${route.path}-${index}`}
            />
          )
        })}
        <Redirect to={`/login`} />
      </Switch>
    </div>
  )
}

export default RestrictedRoutes
