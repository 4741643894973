import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

export interface CategoriesFields {
  orderName: string;
  expiresIn: number;
  categoryId: string;
  categoryName: string;
}
interface UseCategoriesOptions {
  initialValues?: CategoriesFields;
  onSubmit: (
    values: CategoriesFields,
    formikHelpers: FormikHelpers<CategoriesFields>
  ) => Promise<any>;
}

export const useCategoriesFormik = (options: UseCategoriesOptions) => {
  const CategoriesSchema = Yup.object().shape({
    orderName: Yup.string().required("Ju lutemi shenoni emrin e porosise!"),
    categoryId: Yup.string().required("Please select one of the categories"),
    categoryName: Yup.string()
  });

  return useFormik({
    initialValues: {
      orderName: "",
      expiresIn: 5,
      categoryId: "",
      categoryName: ""
    },
    ...options,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CategoriesSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type CategoriesFormik = ReturnType<typeof useCategoriesFormik>;
