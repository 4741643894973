import React from "react";
import Select from "react-select";
import { Theme } from "react-select";

interface Props {
  fieldName: string;
  value: number | undefined;
  onChange: (value: number) => void;
}

export const selectStyles = (theme: Theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: "#2cd0af",
      neutral0: "#121d23",
      primary25: "#2cd0af"
    }
  };
};

const minutes = [
  { value: 5, label: "5 Minuta" },
  { value: 10, label: "10 Minuta" },
  { value: 15, label: "15 Minuta" },
  { value: 30, label: "30 Minuta" },
  { value: 45, label: "45 Minuta" },
  { value: 60, label: "60 Minuta" }
];

export const MinutesSelect = (props: Props) => {
  const { fieldName, value, onChange } = props;

  const hasValue = typeof value === "number";

  return (
    <Select
      options={minutes}
      style={{}}
      theme={selectStyles}
      name={fieldName}
      onChange={(e) => {
        if (!e || e.value === undefined) {
          return;
        }

        onChange(e.value);
      }}
      value={{
        label: `${hasValue ? value : 5} Minuta`,
        value: hasValue ? value : 5
      }}
    />
  );
};
