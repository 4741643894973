import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface PrivacyField {
  oldPassword: string
  password: string
  passwordConfirmation: string
}

interface usePrivacyFormOption {
  initialValues?: PrivacyField
  onSubmit: (
    value: PrivacyField,
    formikHelpers: FormikHelpers<PrivacyField>
  ) => Promise<any>
}

export const usePrivacyFormik = (options: usePrivacyFormOption) => {
  const PrivacySchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    password: Yup.string().required("New password is required"),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords do not match")
      .required("Password Confirmation is required")
      .test(
        "match",
        "Old password and new password should not be the same",
        function (password) {
          return password !== this.parent.oldPassword
        }
      )
  })

  return useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      passwordConfirmation: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: PrivacySchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type PrivacyFormik = ReturnType<typeof usePrivacyFormik>
