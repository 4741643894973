import React from "react";

import { BoxItem } from "../../components/shared/BoxItem/BoxItem";
import { Animate } from "../../components/shared/Animate/Animate";
//styles
import "./Home.scss";

export const Home = () => {
  return (
    <Animate animation="bottom-up">
      <div className="Home centered-content">
        <BoxItem
          icon="add-order"
          type="L"
          isStatic={true}
          isProduct={false}
          path="/add-order"
          itemDescription="SHTO POROSI TË RE"
        />
        <BoxItem
          icon="show-list"
          type="L"
          isStatic={true}
          isProduct={false}
          path="/active-list"
          itemDescription="SHIKO POROSITË"
        />
      </div>
    </Animate>
  );
};
