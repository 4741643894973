import React from "react"
import { ReactComponent as LogoSVG } from "../../assets/icons/logo.svg"
import { useRouter } from "../../lib/hooks/useRouter"
//styles
import "./RestrictedHeader.scss"

export const RestrictedHeader = () => {
  const router = useRouter()

  return (
    <div className="RestrictedHeader">
      <LogoSVG
        className="RestrictedHeader__logo"
        onClick={() => router.history.push("/")}
      />
    </div>
  )
}
