import React from "react";
import { ReactComponent as TrashSvg } from "../../assets/icons/trash.svg";
import { useConfirmation } from "../../lib/context/ConfirmationContext/ConfirmationContext";

import "./SelectedItem.scss";

export interface SelectedOrderItem {
  id: string;
  product_name?: string;
  price: string;
  item?: string;
  quantity?: number;
}

interface Props extends SelectedOrderItem {
  deleteSelectedItem: (id: string) => void;
  changeQuantity: (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: string
  ) => void;
}

export const SelectedItem = (props: Props) => {
  const confirmation = useConfirmation();

  return (
    <div className="SelectedItem">
      <div className="SelectedItem__item">
        <p>{props.item}</p>
        <p className="SelectedItem__price">{props.price} €</p>
      </div>
      <div className="SelectedItem__deleteQuantity">
        <div className="SelectedItem__quantity">
          <select
            className="SelectedItem__quantityInput"
            onChange={(event) => props.changeQuantity(event, props.id)}
          >
            {Array.from({ length: 20 }).map((e, i) => (
              <option
                selected={props.quantity === i + 1}
                key={`option-${i}`}
                value={i + 1}
              >
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div
          className="SelectedItem__delete"
          onClick={() => {
            confirmation
              .confirm("Fshij produktin?")
              .then(() => {
                props.deleteSelectedItem(props.id);
              })
              .catch(() => {});
          }}
        >
          <TrashSvg />
        </div>
      </div>
    </div>
  );
};
