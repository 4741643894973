import React from "react"
import { ReactNode } from "react"
import "./FormBox.scss"

interface Props {
  children: ReactNode
}

export const FormBox = (props: Props) => {
  return <div className="FormBox">{props.children}</div>
}
