import { apiRequest } from "./Api"

export interface User {
  id?: string
  full_name: string
  email: string
  password: string
  role: string
  username: string
}
export interface RegisterInput {
  email: string
}
export interface LoginRegisterResponse {
  token: string
  error?: string
  user: User
}
export interface LoginInput {
  email: string
  password: string
}

export interface ForgotPasswordInput {
  email: string
}
export interface ForgotPasswordResponse {
  success: string
}
export interface ResetPassword {
  password: string
  confirmPassword: string
}

export interface ResetAccountLinkInput {
  email: string
}
export interface ResetPasswordLinkInput {
  email: string
}
export interface ChangePassword {
  old_password: string
  new_password: string
  confirm_password: string
}
export interface UserDetailsResponse {
  user: Array<User>
}

export interface UpdateUserInput {
  full_name: string
  username?: string
  userId?: string
}
export interface UpdateUserResponse {
  updatedUserProfile: User
}
export interface SetAccountInput {
  full_name: string
  password: string
  confirmPassword: string
}

export interface SetAccountResponse {
  token: string
  error?: string
  user: User
}

export const register = async (data: RegisterInput) =>
  apiRequest<RegisterInput, LoginRegisterResponse>(
    "post",
    "api/user/register",
    data
  )
export const login = async (data: LoginInput) =>
  apiRequest<LoginInput, LoginRegisterResponse>("post", "api/user/login", data)

export const forgotPassword = async (data: ForgotPasswordInput) =>
  apiRequest<ForgotPasswordInput, ForgotPasswordResponse>(
    "post",
    "api/user/forgot-password",
    data
  )

export const resetPassword = async (data: ResetPassword, token: string) =>
  apiRequest<ResetPassword, undefined>(
    "post",
    "api/user/reset-password/" + token,
    data
  )

export const resetAccountLink = async (data: ResetAccountLinkInput) =>
  apiRequest<ResetAccountLinkInput, undefined>(
    "post",
    "api/user/resend-set-account-link/",
    data
  )

export const resetPasswordLink = async (data: ResetPasswordLinkInput) =>
  apiRequest<ResetPasswordLinkInput, undefined>(
    "post",
    "api/user/resend-reset-password-link",
    data
  )

export const changePassword = async (data: ChangePassword) => {
  apiRequest<ChangePassword, undefined>(
    "post",
    "api/user/change-password/",
    data
  )
}

export const getUserDetails = async () =>
  apiRequest<undefined, UserDetailsResponse>("get", "api/user/me")

export const updateUser = async (data: UpdateUserInput) =>
  apiRequest<UpdateUserInput, UpdateUserResponse>(
    "post",
    "api/user/update",
    data
  )

export const setAccount = async (data: SetAccountInput, token: string) =>
  apiRequest<SetAccountInput, SetAccountResponse>(
    "post",
    "api/user/set-account/" + token,
    data
  )
