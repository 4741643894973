import React from "react";

import { FormikValues } from "formik";
import { TotalCashInput } from "../../components/shared/TotalCashInput/TotalCashInput";
import "./TotalCash.scss";

interface Props {
  className?: string;
  max?: number;
  onValueChange: (e: any) => void;
  values: FormikValues;
}

export const TotalCash = (props: Props) => {
  const values = props.values;
  return (
    <div className="TotalCash">
      <div className="TotalCash__border">
        <TotalCashInput
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className={"TotalCash__input"}
          placeholder="00.00"
          name="payedAmount"
          id="payedAmount"
          type="number"
          step="0.1"
          min="0"
          max="100"
          value={values.payedAmount ? values.payedAmount : ""}
          onChange={props.onValueChange}
        />
      </div>
      <div className="TotalCash__Currency">
        <p>€</p>
      </div>
    </div>
  );
};
