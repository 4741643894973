import React from "react"
import { Button } from "../../shared/Button/Button"
import { Input } from "../../shared/Input/Input"
import { usePrivacyFormik } from "./lib/usePrivacyFormik"
import { FormBox } from "../../FormBox/FormBox"
import { Animate } from "../../shared/Animate/Animate"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { useRouter } from "../../../lib/hooks/useRouter"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { Alert } from "../../shared/Alert/Alert"
import * as API from "../../../api/Api"

import "./ResetPassword.scss"

export interface Props {
  match: {
    params: {
      token: string
    }
  }
}

export const ResetPassword = (props: Props) => {
  const token = props.match.params.token
  const [alert, setAlert] = useState<boolean>(false)
  const errorHandler = useErrorHandler()
  const router = useRouter()

  const formik = usePrivacyFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.resetPassword(
          {
            password: values.password,
            confirmPassword: values.passwordConfirmation
          },
          token
        )
        setAlert(true)
        router.history.push("/login")
        formikHelpers.resetForm()
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })

  return (
    <div className="ResetPassword centered-content">
      <Animate animation="bottom-up">
        <>
          <FormBox>
            <form onSubmit={formik.handleSubmit}>
              <h5 className="ResetPassword__title">Privacy</h5>
              <Input
                placeholder="Password"
                type="password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.password && formik.touched.password}
                isInvalid={!!formik.errors.password && formik.touched.password}
                error={formik.errors.password}
              />
              <Input
                placeholder="Password Confirmation"
                type="password"
                name=""
                id="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  !!formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                }
                isInvalid={
                  !!formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                }
                error={formik.errors.passwordConfirmation}
              />

              <Alert
                duration={2}
                color="success"
                status={alert}
                onClose={() => setAlert(false)}
              >
                Password changed successfully!
              </Alert>
              {errorHandler.error === "jwt expired" ? (
                <Alert
                  status={true}
                  onClose={errorHandler.reset}
                  color="danger"
                >
                  Token has expired!
                  <NavLink
                    className="SetPassword__resend-email"
                    to={`/reset-password-link/${token}`}
                  >
                    Resend your email?
                  </NavLink>
                </Alert>
              ) : (
                <ErrorMessage
                  onClose={errorHandler.reset}
                  errorData={errorHandler}
                />
              )}

              <Button
                type="button"
                disabled={formik.isSubmitting}
                onClick={formik.submitForm}
              >
                {formik.isSubmitting ? "RESETTING..." : "RESET"}
              </Button>
            </form>
          </FormBox>
        </>
      </Animate>
    </div>
  )
}
