/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useAuthContext } from "./lib/context/AuthContext/AuthContext";
import { ReactComponent as LogoSvg } from "./assets/icons/logo.svg";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import RestrictedRoutes from "./routes/RestrictedRoutes";

//Styles
import "./App.scss";

function App() {
  const authCtx = useAuthContext();
  if (authCtx.isLoading)
    return (
      <div className="App__logo">
        <LogoSvg />
      </div>
    );
  return (
    <div className="App">
      {authCtx.isAuthenticated ? <AuthenticatedRoutes /> : <RestrictedRoutes />}
    </div>
  );
}

export default App;
