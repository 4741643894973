import { apiRequest } from "./Api";

export interface Order {
  category: string;
  changed: number;
  deadline: string;
  id: string;
  name: string;
  order_author: string;
  order_customers: string[];
  payed_amount: number;
  total: number;
}

export interface AllOrdersResponse {
  activeOrders: Order[];
  inActiveOrders: Order[];
}

export interface OrderDetailByUser {
  [userId: string]: {
    full_name: string;
    order_id: string;
    change: number;
    payed_amount: number;
    products: [
      {
        product_id: string;
        price: string;
        product_name: string;
        quantity: number;
      }
    ];
    total: number;
  };
}
export interface OrderSummaryProduct {
  name: string;
  quantity: number;
}

export interface OrderSummary {
  products: OrderSummaryProduct[];
  total: string;
}
export interface OrderSummarySocket {
  orderSummary: {
    products: OrderSummaryProduct[];
    total: string;
  };
}

export interface Order {
  orderDetailsByUser: OrderDetailByUser;
  orderName: string;
  deadline: string;
  orderId: string;
  orderAuthor: string;
  categoryId: string;
  orderSummary: OrderSummary;
}

interface OrderItemInput {
  orderId: string;
  products: { product_id: string; quantity: number }[];
  payedAmount: number;
  total: number;
}
interface OrderInput {
  name: string;
  deadline?: string;
  title: string;
}
interface OrderResponse {
  order: {
    category_id: string;
    deadline: string;
    id: string;
    name: string;
    user_id: string;
  };
}

interface OrderPerUserResponse {
  order: Order;
}

export const createOrder = async (data: OrderInput) =>
  apiRequest<OrderInput, OrderResponse>("post", "api/order/create", data);

export const createOrderItem = async (data: OrderItemInput) =>
  apiRequest<OrderItemInput, undefined>(
    "post",
    "api/order-item/create-or-update",
    data
  );

export const getAllOrders = async () =>
  apiRequest<undefined, any>("get", "api/order/get-all-orders");

export const getOrder = async (orderId: string) =>
  apiRequest<undefined, Order>(
    "get",
    `api/order/get-order-details?orderId=${orderId}`
  );

export const getOrderPerUser = async (orderId: string) =>
  apiRequest<string, OrderPerUserResponse>(
    "get",
    `api/order/get-order-per-user?orderId=${orderId}`
  );

export const deleteOrder = async (orderId: string) =>
  apiRequest<string, undefined>(
    "delete",
    `api/order/delete?orderId=${orderId}`
  );

export const deleteYourOrder = async (orderId: string) =>
  apiRequest<string, undefined>(
    "delete",
    `api/order/order-of-user?orderId=${orderId}`
  );

export const getInactiveOrders = async () =>
  apiRequest<undefined, any>("get", `api/order/get-inactive-orders`);
