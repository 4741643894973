import React, { useCallback, useEffect, useState } from "react";
import { Receipt } from "../../components/Receipt/Receipt";
import { BoxItem } from "../../components/shared/BoxItem/BoxItem";
import { BackButton } from "../../components/shared/BackButton/BackButton";
import { OrderPageItemLoader } from "./OrderPageItemLoader";
import { Animate } from "../../components/shared/Animate/Animate";
import { AddProductButton } from "../../components/Modals/AddProductModal/AddProductButton";
import { BillButton } from "../../components/BillButton/BillButton";
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage";
import { Product } from "../../api/Api";
import { useErrorHandler } from "../../lib/hooks/useErrorHandler";
import { useApiCall } from "../../lib/hooks/useApiCall";
import * as API from "../../api/Api";

//styles
import "./OrderPage.scss";
import { useSocketContext } from "../../lib/context/SocketContext/SocketContext";

interface Props {
  match: {
    params: {
      categoryId: string;
      orderId: string;
    };
  };
}
export interface OrderItems {
  category_id?: string;
  id: string;
  price: string;
  product_name: string;
  quantity?: number;
  media?: string;
}
export interface OrderItem {
  category_id?: string;
  product_id: string;
  price: string;
  product_name: string;
  quantity?: number;
  media?: string;
}

interface ProductId {
  productId: string;
}
export const OrderPage = (props: Props) => {
  const [productsByCategory, setProductsByCategory] = useState<Product[]>([]);
  const [itemSelected, setItemSelected] = useState<OrderItems[]>([]);
  const [toggleBill, setToggleBill] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const orderId = props.match.params.orderId;
  const categoryId = props.match.params.categoryId;
  const { loading, data: orderPerUser, error } = useApiCall(
    API.getOrderPerUser,
    [orderId]
  );

  const { socket } = useSocketContext();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    socket.on("createProduct", (data: API.Product) =>
      setProductsByCategory((prevState) => [...prevState, data])
    );
  }, []);

  useEffect(() => {
    socket.on("deleteProduct", (data: ProductId) =>
      setProductsByCategory((prevState) =>
        prevState.filter((product) => product.id !== data.productId)
      )
    );
  }, []);

  useEffect(() => {
    if (orderPerUser && Object.keys(orderPerUser.order).length !== 0) {
      const itemsSelected = Object.values(orderPerUser?.order)[0].products;
      itemsSelected.map((item: OrderItem) => {
        setItemSelected((prevState) => [
          ...prevState,
          {
            id: item.product_id,
            product_name: item.product_name,
            price: item.price,
            quantity: item.quantity
          }
        ]);
      });
    }
  }, [orderPerUser]);

  const getProductsByCategory = useCallback(async () => {
    try {
      const productsByCategory = await API.getProductsByCategory(categoryId);
      setProductsByCategory(productsByCategory.product);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setIsLoading(false);
    }
  }, [categoryId]);

  useEffect(() => {
    getProductsByCategory();
  }, [getProductsByCategory]);

  //checks if an item is in order list if not, it adds it
  const addOrderItem = (id: string, item: string, price: string) => {
    if (doesItemExist(id)) {
      return;
    }

    setItemSelected([
      ...itemSelected,
      {
        id: id,
        product_name: item,
        price: price,
        quantity: 1
      }
    ]);
  };

  //checks if an item exists in order list
  const doesItemExist = (id: string) => {
    return itemSelected?.some((element) => element.id === id);
  };

  //deletes specified item in order list
  const deleteSelectedItem = (id: string) => {
    const updatedItems = itemSelected?.filter((item) => item.id !== id);
    setItemSelected(updatedItems);
  };

  //changes quantity of an existing item

  const changeItemQuantity = (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: string
  ) => {
    setItemSelected(
      itemSelected?.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: parseInt(e.currentTarget.value) };
        }
        return item;
      })
    );
  };

  //calculates total price of whole list
  const totalPrice = () => {
    let total = 0;

    productsByCategory &&
      itemSelected.forEach((item) => {
        if (item.quantity) {
          total += item.quantity * parseFloat(item.price);
        }
      });
    return total;
  };

  //change which component is shown when button is clicked while on mobile
  const toggleBillFeature = () => {
    setToggleBill((prev) => !prev);
  };

  //return only orderItems ids
  const productIds = itemSelected?.map((orderItem) => orderItem.id);

  return (
    <div className="OrderPage">
      <BackButton className="OrderPage__button" to="/add-order">
        Back
      </BackButton>
      <Animate animation="bottom-up">
        <div className="OrderPage__container">
          <BillButton onClick={() => toggleBillFeature()} />
          {isLoading ? (
            <OrderPageItemLoader />
          ) : (
            <div
              className={
                toggleBill
                  ? " OrderPage__products OrderPage__products--hide"
                  : " OrderPage__products"
              }
            >
              {productsByCategory?.map((product) => {
                return (
                  <BoxItem
                    key={product.id}
                    id={product.id}
                    isProduct={true}
                    type="M"
                    picturePath={product.media}
                    itemDescription={product.product_name}
                    productPrice={product.price}
                    isProductSelected={productIds?.includes(product.id)}
                    onClick={() => {
                      addOrderItem(
                        product.id,
                        product.product_name,
                        product.price
                      );
                    }}
                  />
                );
              })}
              <AddProductButton categoryId={categoryId} />
            </div>
          )}
          <ErrorMessage errorData={errorHandler} />
          <div
            className={
              toggleBill
                ? "OrderPage__receipt OrderPage__receipt--slideIn"
                : "OrderPage__receipt--slideOut OrderPage__receipt--hide"
            }
          >
            <Receipt
              orderId={orderId}
              orders={itemSelected}
              deleteSelectedItem={(id) => deleteSelectedItem(id)}
              changeQuantity={(event, id) => changeItemQuantity(event, id)}
              totalPrice={totalPrice()}
            />
          </div>
        </div>
      </Animate>
    </div>
  );
};
