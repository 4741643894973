interface timeLeftProps {
  total: number
  hours: number
  minutes: number
  expired: boolean
}

export const getIndicator = (timeLeft: timeLeftProps) => {
  if (timeLeft.expired) return "grey"

  if (timeLeft.minutes >= 10 || timeLeft.hours >= 1) return "green"
  if (timeLeft.minutes < 10 && timeLeft.minutes >= 5 && timeLeft.hours < 1)
    return "yellow"
  if (timeLeft.minutes < 5 && timeLeft.hours < 1) return "red"
}
