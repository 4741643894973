import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

interface OrderFields {
  orderId: string;
  products: { product_id: string; quantity: number }[];
  payedAmount: number;
  total: number;
}
interface UseOrderOptions {
  initialValues?: OrderFields;
  onSubmit: (
    values: OrderFields,
    formikHelpers: FormikHelpers<OrderFields>
  ) => Promise<any>;
}

export const useOrderFormik = (options: UseOrderOptions) => {
  const OrderSchema = Yup.object().shape({
    payedAmount: Yup.number()
      .max(100, "Nuk mund te paguash me shume se 100 euro")
      .test(
        "maxDigitsAfterDecimal",
        "Nuk mund te fusesh me shume se 2 numra pas presjes dhjetore",
        (payedAmount: any) => /^\d+(\.\d{1,2})?$/.test(payedAmount)
      )
  });

  return useFormik({
    initialValues: {
      orderId: "",
      products: [
        {
          product_id: "",
          quantity: 0
        }
      ],
      payedAmount: 0,
      total: 0
    },

    validateOnBlur: true,
    validateOnChange: true,
    ...options.initialValues,
    validationSchema: OrderSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type OrderFormik = ReturnType<typeof useOrderFormik>;
