import React from "react"
import SetAccountForm from "../../components/User/SetAccountForm/SetAccountForm"
import { FormBox } from "../../components/FormBox/FormBox"
import { NavLink } from "react-router-dom"
import { Props } from "../../components/User/ResetPassword/ResetPassword"
import { Animate } from "../../components/shared/Animate/Animate"
import "./SetAccount.scss"

const SetAccount = (props: Props) => {
  const token = props.match.params.token
  return (
    <Animate animation="bottom-up">
      <div className="SetAccount centered-content">
        <FormBox>
          <SetAccountForm token={token} />
          <NavLink className="SetAccount__nav" to="/login">
            Already have an account? Log in
          </NavLink>
        </FormBox>
      </div>
    </Animate>
  )
}

export default SetAccount
