import React, { useState } from "react"
import { Button } from "../../shared/Button/Button"
import { Input } from "../../shared/Input/Input"
import { useForgotPasswordFormik } from "./lib/useForgotPasswordFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import * as API from "../../../api/Api"

import "./ForgotPasswordForm.scss"
import { Alert } from "../../shared/Alert/Alert"

export interface ForgotPasswordField {
  email: string
}

export const ForgotPasswordForm = () => {
  const [alert, setAlert] = useState<boolean>(false)
  const errorHandler = useErrorHandler()

  const formik = useForgotPasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.forgotPassword({
          email: values.email
        })
        setAlert(true)
        formikHelpers.resetForm()
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })

  return (
    <div className="ForgotPasswordForm">
      <form onSubmit={formik.handleSubmit}>
        <h5 className="ForgotPasswordForm__title">Forgot Password</h5>
        <Input
          placeholder="Write your email"
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.email && formik.touched.email}
          isInvalid={!!formik.errors.email && formik.touched.email}
          error={formik.errors.email}
        />

        <Alert
          duration={2}
          color="success"
          status={alert}
          onClose={() => setAlert(false)}
        >
          Check your email!
        </Alert>

        <ErrorMessage errorData={errorHandler} onClose={errorHandler.reset} />
        <Button
          type="button"
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          {formik.isSubmitting ? "SENDING..." : "SEND"}
        </Button>
      </form>
    </div>
  )
}
