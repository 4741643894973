export const getTimeLeft = (expireTime: string) => {
  const total = Date.parse(expireTime) - Date.parse(new Date().toString())
  if (total <= 0) {
    return {
      total: 0,
      hours: 0,
      minutes: 0,
      expired: true
    }
  }

  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)

  return {
    total,
    hours,
    minutes,
    expired: false
  }
}
