/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { RouteProps, Switch } from "react-router-dom"
import { PublicRoute } from "./PublicRoute"
import { PrivateRoute } from "./PrivateRoute"
import { Login } from "../pages/Login/Login"
import { Register } from "../pages/Register/Register"
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword"
import { Profile } from "../pages/Profile/Profile"
import { Home } from "../pages/Home/Home"
import { Page404 } from "../pages/Page404/Page404"
import { RestrictedRoute } from "./RestrictedRoute"
import { AddOrder } from "../pages/Categories/AddOrder"
import { ActiveList } from "../pages/ActiveList/ActiveList"
import { OrderPage } from "../pages/OrderPage/OrderPage"
import { Summary } from "../pages/Summary/Summary"
import { ResetAccountLink } from "../pages/ResetAccountLink/ResetAccountLink"
import { ResetPassword } from "../components/User/ResetPassword/ResetPassword"
import { ProfileForm } from "../components/User/ProfileForm/ProfileForm"
import { ChangePasswordForm } from "../components/User/ChangePassword/ChangePasswordForm"
import { ResetPasswordLink } from "../pages/ResetPasswordLink/ResetPasswordLink"
import { OrderArchive } from "../pages/OrderArchive/OrderArchive"
import SetAccount from "../pages/SetAccount/SetAccount"

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED
}
interface AppRoute extends RouteProps {
  type?: RouteType
}
export const AppRoutes: AppRoute[] = [
  // Restricted Routes
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "login",
    component: Login
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "register",
    component: Register
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword
  },

  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "set-account/:token",
    component: SetAccount
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "reset-password/:token",
    component: ResetPassword
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "reset-account-link/:token",
    component: ResetAccountLink
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "reset-password-link/:token",
    component: ResetPasswordLink
  },
  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: "my-profile",
    component: Profile
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "main-page",
    component: Home
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "add-order",
    component: AddOrder
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "order-page/:categoryId/:orderId",
    component: OrderPage
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "active-list",
    component: ActiveList
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "summary/:id",
    component: Summary
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "my-profile",
    component: ProfileForm
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "my-profile/change-password",
    component: ChangePasswordForm
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "archive",
    component: OrderArchive
  },
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "/",
    component: Home
  }
]

export const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r
        if (type === RouteType.PRIVATE) {
          return (
            <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          )
        }
        if (type === RouteType.RESTRICTED) {
          return (
            <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          )
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
      })}
      <PublicRoute component={Page404} />
    </Switch>
  )
}
