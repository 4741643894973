import React from "react"
import { FileWithPath, useDropzone } from "react-dropzone"
import { Button } from "../Button/Button"
import { Icon } from "../Icon/Icon"

import "./DragAndDrop.scss"

interface Props {
  onPhotoSelect: (photoFile: File) => void
}

function DragAndDrop(props: Props) {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (files) => {
      props.onPhotoSelect(files[0])
    }
  })

  return (
    <div className="DragAndDrop">
      <section className="DragAndDrop__container">
        <div {...getRootProps({ className: "DragAndDrop__dropzone" })}>
          <input {...getInputProps()} />
          <Icon icon="dragAndDrop" />
          <p className="DragAndDrop__title">Drag a Photo Here</p>
          <p className="DragAndDrop__message">— or —</p>

          <Button
            className="DragAndDrop__select-photo"
            type="button"
            onClick={open}
          >
            select a photo from your computer
          </Button>
        </div>
        <aside>
          <ul>
            {acceptedFiles.map((file: FileWithPath) => (
              <li key={file.path}>Selected file: {file.path}</li>
            ))}
          </ul>
        </aside>
      </section>
    </div>
  )
}

export default DragAndDrop
