import React from "react"
import { ResetPasswordLinkForm } from "../../components/User/ResetPasswordLinkForm/ResetPasswordLinkForm"
import { Animate } from "../../components/shared/Animate/Animate"
import { FormBox } from "../../components/FormBox/FormBox"

//styles
import "./ResetPasswordLink.scss"

export const ResetPasswordLink = () => {
  return (
    <Animate animation="bottom-up">
      <div className="ResetPasswordLink centered-content">
        <FormBox>
          <ResetPasswordLinkForm />
        </FormBox>
      </div>
    </Animate>
  )
}
