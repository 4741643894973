import React, { useState } from "react";
import { Button } from "../../shared/Button/Button";
import { Input } from "../../shared/Input/Input";
import { useProfileFormik } from "./lib/useProfileFormik";
import { FormBox } from "../../FormBox/FormBox";
import { Animate } from "../../shared/Animate/Animate";
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { Alert } from "../../shared/Alert/Alert";
import * as API from "../../../api/Api";

import "./ProfileForm.scss";

export const ProfileForm = () => {
  const [alert, setAlert] = useState<boolean>(false);
  const errorHandler = useErrorHandler();
  const authContext = useAuthContext();
  const user = authContext.user;

  const formik = useProfileFormik({
    initialValues: {
      full_name: user?.full_name || "",
      email: user?.email || ""
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.updateUser({
          full_name: values.full_name
        });
        setAlert(true);
        authContext.updateUser(res);
        formikHelpers.resetForm();
      } catch (e: any) {
        errorHandler.handleError(e);
      }
    }
  });
  return (
    <Animate animation="bottom-up">
      <div className="ProfileForm centered-content">
        <FormBox>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <h5 className="ProfileForm__title">My profile</h5>
              <Input
                placeholder="Full name"
                type="text"
                name="full_name"
                id="full_name"
                value={formik.values.full_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.full_name && formik.touched.full_name}
                isInvalid={
                  !!formik.errors.full_name && formik.touched.full_name
                }
                error={formik.errors.full_name}
              />

              <Input
                placeholder="Email"
                type="email"
                name="email"
                id="email"
                value={formik.values.email}
                disabled
              />
              <Alert
                duration={2}
                color="success"
                status={alert}
                onClose={() => setAlert(false)}
              >
                Changes saved successfully!
              </Alert>
              <ErrorMessage
                errorData={errorHandler}
                onClose={errorHandler.reset}
              />
              <Button disabled={formik.isSubmitting} type="submit">
                {formik.isSubmitting ? "SAVING CHANGES..." : "SAVE CHANGES"}
              </Button>
            </form>
          </div>
        </FormBox>
      </div>
    </Animate>
  );
};
