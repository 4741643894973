import React, { useState } from "react"
import classNames from "classnames"
import {
  Label,
  Input as InputRS,
  InputProps,
  FormFeedback,
  FormGroup
} from "reactstrap"

//styles
import "./Input.scss"

export const Input = (props: InputProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  setTimeout(() => {
    if (props.value) {
      setIsActive(true)
    }
  }, 100)

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value

    setIsActive(text !== "")

    if (props.onChange) {
      props.onChange(e)
    }
  }

  const { placeholder, isInvalid, ...rest } = props

  return (
    <div className={`Input ${props.className}`}>
      <div className="Input--float-label">
        <FormGroup className={classNames({ "is-invalid": props.invalid })}>
          <Label
            className={classNames({
              "Input--active": isActive,
              "Input--disabled": props.value && props.disabled
            })}
            htmlFor={props.id}
          >
            {placeholder}
          </Label>
          <InputRS {...rest} value={props.value} onChange={handleTextChange} />
          <FormFeedback>{props.error}</FormFeedback>
        </FormGroup>
      </div>
    </div>
  )
}
