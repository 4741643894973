import React, { useState } from "react"
//Components
import { useRegisterFormik } from "../RegisterForm/lib/useRegisterFormik"
import { Button } from "../../shared/Button/Button"
import { Input } from "../../shared/Input/Input"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { Alert } from "../../shared/Alert/Alert"
import * as API from "../../../api/Api"

//Style
import "./ResetAccountLinkForm.scss"

export interface RegisterFields {
  email: string
}

export const ResetAccountLinkForm = () => {
  const [alert, setAlert] = useState<boolean>(false)
  const errorHandler = useErrorHandler()
  const formik = useRegisterFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.resetAccountLink({
          email: values.email
        })
        setAlert(true)
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })

  return (
    <div className="ResetAccountLinkForm">
      <form onSubmit={formik.handleSubmit}>
        <h5 className="ResetAccountLinkForm__title">Reset account link</h5>
        <Input
          placeholder="Write your email"
          type="email"
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.email && formik.touched.email}
          isInvalid={!!formik.errors.email && formik.touched.email}
          error={formik.errors.email}
        />
        <Alert
          duration={2}
          color="success"
          status={alert}
          onClose={() => setAlert(false)}
        >
          Check your email!
        </Alert>

        <ErrorMessage errorData={errorHandler} onClose={errorHandler.reset} />

        <Button
          disabled={formik.isSubmitting}
          type="submit"
          onClick={formik.submitForm}
        >
          {formik.isSubmitting ? "RESETTING..." : "RESET"}
        </Button>
      </form>
    </div>
  )
}
