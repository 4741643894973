/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { Button } from "../../components/shared/Button/Button";
import { ModalFooter } from "reactstrap";
import { Modal } from "../../components/shared/Modal/Modal";
import { useRouter } from "../../lib/hooks/useRouter";

import "./ConfirmationModal.scss";

export interface Props {
  showCloseButton?: boolean;
  confirmationData: {
    text?: string;
    isOpen: boolean;
    isLoading?: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  };
}

export default function ConfirmationModal(props: Props) {
  const router = useRouter();

  useEffect(() => {
    cancel();
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirm = () => {
    if (props.confirmationData.onConfirm) {
      props.confirmationData.onConfirm();
    }
  };

  const cancel = () => {
    if (props.confirmationData.onCancel) {
      props.confirmationData.onCancel();
    }
  };

  return (
    <Modal
      className="ConfirmationModal"
      isOpen={props.confirmationData.isOpen}
      toggle={() => cancel()}
    >
      <div className="ConfirmationModal__container">
        <h4 className="ConfirmationModal__text">
          {props.confirmationData.text}
        </h4>
        <ModalFooter className="ConfirmationModal__footer">
          <button className="ConfirmationModal__cancel" onClick={cancel}>
            X
          </button>
          <Button
            className="ConfirmationModal__confirm "
            type="submit"
            onClick={confirm}
          >
            VAZHDO
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
