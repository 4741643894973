/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { UIContext, UIContextType } from "./UIContext"

interface UIContextProviderProps {
  children: React.ReactNode | null
}

//uncomment if needed
// interface UIContextProviderState {}

export const UIContextProvider = (props: UIContextProviderProps) => {
  // const [
  //   /*state, setState*/
  // ] = useState<UIContextProviderState>({})

  const context: UIContextType = {}

  return (
    <UIContext.Provider value={context}>{props.children}</UIContext.Provider>
  )
}
