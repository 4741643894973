import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface SetAccountFields {
  full_name: string
  username: string
  password: string
  confirmPassword: string
}
interface UseSetAccountFormOptions {
  initialValues?: SetAccountFields
  onSubmit: (
    values: SetAccountFields,
    formikHelpers: FormikHelpers<SetAccountFields>
  ) => Promise<any>
}

export const useSetAccountFormik = (options: UseSetAccountFormOptions) => {
  const LoginSchema = Yup.object().shape({
    full_name: Yup.string().required("Fullname is required"),

    // username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords do not match")
      .required("Password Confirmation is required")
      .min(8, "Password must be inbetween 8 and 30 characters!")
  })

  return useFormik({
    initialValues: {
      full_name: "",
      username: "",
      password: "",
      confirmPassword: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type SetAccountFormik = ReturnType<typeof useSetAccountFormik>
