import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface ProfileField {
  full_name: string

  email: string
}
interface useProfileFormOption {
  initialValues?: ProfileField
  onSubmit: (
    value: ProfileField,
    formikHelpers: FormikHelpers<ProfileField>
  ) => Promise<any>
}

export const useProfileFormik = (options: useProfileFormOption) => {
  const ProfileSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required")
  })

  return useFormik({
    initialValues: {
      full_name: "",
      email: "",
      ...options.initialValues
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProfileSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type ProfileFormik = ReturnType<typeof useProfileFormik>
