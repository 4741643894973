import React from "react"

import { ChangePasswordForm } from "../../components/User/ChangePassword/ChangePasswordForm"
import { ProfileForm } from "../../components/User/ProfileForm/ProfileForm"
import { NavLink, Route, Switch } from "react-router-dom"

//styles
import "./Profile.scss"

export const Profile = () => {
  return (
    <div className="Profile">
      <div className="Profile__sidebar">
        <h5 className="Profile__sidebar-title">My account</h5>
        <ul>
          <li>
            <NavLink className="Profile__sidebar-nav" exact to="/my-profile">
              My Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              className="Profile__sidebar-nav"
              to="/my-profile/change-password"
            >
              Change Password
            </NavLink>
          </li>
        </ul>
      </div>
      <Switch>
        <Route exact path="/my-profile" component={ProfileForm} />
        <Route
          path="/my-profile/change-password/"
          component={ChangePasswordForm}
        />
        <Route path="*" exact component={ProfileForm} />
      </Switch>
    </div>
  )
}
