import React from "react"
import { Button } from "../../shared/Button/Button"
import { Input } from "../../shared/Input/Input"
import { usePrivacyFormik } from "./lib/usePrivacyFormik"
import { useState } from "react"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { Animate } from "../../shared/Animate/Animate"
import { FormBox } from "../../FormBox/FormBox"
import { Alert } from "../../shared/Alert/Alert"
import * as API from "../../../api/Api"

import "./ChangePasswordForm.scss"

export const ChangePasswordForm = () => {
  const [alert, setAlert] = useState<boolean>(false)
  const errorHandler = useErrorHandler()

  const formik = usePrivacyFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await API.changePassword({
          old_password: values.oldPassword,
          new_password: values.password,
          confirm_password: values.passwordConfirmation
        })
        setAlert(true)
        formikHelpers.resetForm()
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })

  return (
    <Animate animation="bottom-up">
      <div className="ChangePasswordForm centered-content">
        <FormBox>
          <div className="PrivacyForm__content">
            <form onSubmit={formik.handleSubmit}>
              <h5 className="PrivacyForm__title">Change password</h5>
              <Input
                placeholder="Old password"
                type="password"
                name="oldPassword"
                id="oldPassword"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  !!formik.errors.oldPassword && formik.touched.oldPassword
                }
                isInvalid={
                  !!formik.errors.oldPassword && formik.touched.oldPassword
                }
                error={formik.errors.oldPassword}
              />
              <Input
                placeholder="Create new password"
                type="password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={!!formik.errors.password && formik.touched.password}
                isInvalid={!!formik.errors.password && formik.touched.password}
                error={formik.errors.password}
              />
              <Input
                placeholder="Confirm new password"
                type="password"
                name="passwordConfirmation"
                id="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  !!formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                }
                isInvalid={
                  !!formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                }
                error={formik.errors.passwordConfirmation}
              />

              <Alert
                duration={2}
                color="success"
                status={alert}
                onClose={() => setAlert(false)}
              >
                Password changed successfully!
              </Alert>
              <ErrorMessage
                errorData={errorHandler}
                onClose={errorHandler.reset}
              />
              <Button
                // className="PrivacyForm__button"
                type="button"
                onClick={formik.submitForm}
              >
                {formik.isSubmitting ? "SAVING CHANGES..." : "SAVE CHANGES"}
              </Button>
            </form>
          </div>
        </FormBox>
      </div>
    </Animate>
  )
}
