import React, { useState } from "react"
import { BoxItem, BoxItemType } from "../../shared/BoxItem/BoxItem"
import { IconDefinition } from "../../shared/Icon/Icon"
import { AddProductModal } from "./AddProductModal"
//styles

const addProduct = {
  Icon: "add-order" as IconDefinition,
  Type: "M" as BoxItemType,
  ItemDescription: "SHTO PRODUKT"
}
interface Props {
  categoryId: string
}
export const AddProductButton = (props: Props) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <BoxItem
        icon={addProduct.Icon}
        isStatic={true}
        type={addProduct.Type}
        itemDescription={addProduct.ItemDescription}
        onClick={toggle}
      />
      <AddProductModal
        categoryId={props.categoryId}
        isOpen={modal}
        fade={false}
        toggle={toggle}
      />
    </>
  )
}
