import React from "react"
import io from "socket.io-client"
import { SocketContext, SocketContextType } from "./SocketContext"

interface SocketContextProviderProps {
  children: React.ReactNode | null
}

const SocketContextProvider = (props: SocketContextProviderProps) => {
  const socket = io("https://api.kutia-porosia.k-clients.com")

  const context: SocketContextType = {
    socket: socket
  }

  if (!socket) {
    throw new Error("Socket was not provided")
  }

  return (
    <SocketContext.Provider value={context}>
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketContextProvider
