/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { AppRoutes, RouteType } from "./Routes"
import Header from "../partials/Header/Header"
import { Redirect, Switch } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"
import { Container } from "reactstrap"

//styles
import '../styles/main.scss'

const AuthenticatedRoutes = () => {
  const authRoutes = AppRoutes.filter(
    (appRoute) => appRoute.type === RouteType.PRIVATE
  )

  return (
    <Container className="container">
      <Header />
      <Switch>
        {authRoutes.map((route, index) => (
          <PrivateRoute
            {...route}
            path={`/${route.path}`}
            key={`${route.path}-${index}`}
          />
        ))}
        <Redirect to="/main-page" />
      </Switch>
    </Container>
  )
}

export default AuthenticatedRoutes
