import React from "react";
import { Icon, IconDefinition } from "../Icon/Icon";
import { useRouter } from "../../../lib/hooks/useRouter";
import { Button as RButton, ButtonProps } from "reactstrap";
//styles
import cs from "classnames";
import "./Button.scss";

interface Props extends ButtonProps {
  type?: "button" | "reset" | "submit" | undefined;
  icon?: IconDefinition;
  children?: string | JSX.Element[] | JSX.Element;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  to?: string;
}

export const Button = (props: Props) => {
  const router = useRouter();
  const { className, loading, type, ...rest } = props;
  const classNames = cs("Button", className);

  const handleClick = () => {
    if (!props.to) {
      if (props.onClick) {
        props.onClick();
      }
      return;
    }

    router.history.push(props.to);
  };

  return (
    <RButton
      disabled={loading}
      className={classNames}
      onClick={handleClick}
      type={type}
      {...rest}
    >
      {props.children}
      {props.icon && <Icon className="Button__aside" icon={props.icon} />}
    </RButton>
  );
};
