import React from "react";
import { ReactComponent as TrashSvg } from "../../../assets/icons/trash.svg";

import "./DeleteButton.scss";

interface Props {
  className?: string;
  onClick?: (e: any) => void;
}

export const DeleteButton = (props: Props) => {
  const handleClick = (e: any) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <button
      type="button"
      className={`DeleteButton ${props.className}`}
      onClick={handleClick}
    >
      <TrashSvg />
    </button>
  );
};
