import React from "react"
import "./Animate.scss"

interface Props {
  animation: "right-to-left" | "bottom-up"
  children: JSX.Element
}

export const Animate = (props: Props) => {
  return <span className={`Animate__${props.animation}`}>{props.children}</span>
}
