import React from "react"
import "./ActiveUsers.scss"

interface Props {
  users?: string[]
}

export const ActiveUsers = (props: Props) => {
  let userLimit = 3

  //uses usernames from initial array to get user initials
  const getUserInitials = (user: string, index: number) => {
    let isConcatenated = user.split(" ").length === 1

    if (index > userLimit) return

    //fourth bubble shows the number of users left on list
    if (index === userLimit) {
      if (props.users) return "+" + (props.users.length - userLimit)
    }

    //checks if username comes concatenated or separated by space
    return isConcatenated
      ? user.split(" ")[0].split("")[0]
      : user.split(" ")[0].split("")[0] + user.split(" ")[1].split("")[0]
  }

  return (
    <div className="ActiveUsers">
      {props.users?.map((value: string, index: number) => {
        if (props.users?.length !== 0) {
          return (
            <div key={index}>
              {getUserInitials(value, index) !== undefined ? (
                <div className="ActiveUsers__userBubble">
                  {getUserInitials(value, index)}
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        }
        return null
      })}
    </div>
  )
}
