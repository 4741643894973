import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { LoginForm } from "../../components/User/LoginForm/LoginForm"
import { NavLink } from "react-router-dom"
import { Animate } from "../../components/shared/Animate/Animate"
import { FormBox } from "../../components/FormBox/FormBox"
//styles
import "./Login.scss"

interface Props {}

export const Login = (props: RouteComponentProps<Props>) => {
  return (
    <Animate animation="bottom-up">
      <div className="Login centered-content">
        <FormBox>
          <LoginForm />
          <NavLink className="Login__nav" to="/forgot-password">
            forgot password?
          </NavLink>
          <NavLink className="Login__nav" to="/register">
            Don't have an account yet? Register
          </NavLink>
        </FormBox>
      </div>
    </Animate>
  )
}
