import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"
import { RegisterFields } from "../RegisterForm"

interface UseRegisterFormOptions {
  initialValues?: RegisterFields
  onSubmit: (
    values: RegisterFields,
    formikHelpers: FormikHelpers<RegisterFields>
  ) => Promise<any>
}

export const useRegisterFormik = (options: UseRegisterFormOptions) => {
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required")
  })

  return useFormik({
    initialValues: {
      email: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type RegisterFormik = ReturnType<typeof useRegisterFormik>
