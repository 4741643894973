import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { OrderDetailByUser } from "../../../api/Order";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { DeleteButton } from "../../shared/DeleteButton/DeleteButton";
import { useOrderFormik } from "../../Receipt/lib/useOrderFormik";
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useConfirmation } from "../../../lib/context/ConfirmationContext/ConfirmationContext";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import Vector from "../../../assets/images/Vector.svg";
import * as API from "../../../api/Api";

import cs from "classnames";
import "./SummaryTable.scss";
import { Button } from "../../shared/Button/Button";
import { socket } from "../../../lib/context/SocketContext/SocketContext";
import { BoxItem } from "../../shared/BoxItem/BoxItem";

interface Props {
  categoryId: string;
  orderDetailsByUser: OrderDetailByUser;
  onDelete: (orderId: string) => void;
  isDeleting: boolean;
  orderId: string;
  orderExpired?: boolean;
}
interface IDs {
  orderId: string;
  user_id: string;
}

export const SummaryTable = (props: Props) => {
  const authCtx = useAuthContext();
  const [order, setOrder] = useState<API.Order>();
  const errorHandler = useErrorHandler();
  const router = useRouter();

  const confirmation = useConfirmation();

  const formik = useOrderFormik({
    onSubmit: async (values, formikHelpers) => {
      props.onDelete(props.orderId);
      try {
        await API.createOrderItem({
          orderId: props.orderId,
          products: values.products,
          payedAmount: values.payedAmount,
          total: values.total
        });
        router.history.push(`/order-page/${props.categoryId}/${props.orderId}`);
        formikHelpers.resetForm();
      } catch (e: any) {
        errorHandler.handleError(e);
      }
    }
  });

  if (!props.orderDetailsByUser) {
    return null;
  }
  socket.on("deleteOrderOfUser", (data: IDs) => {
    if (
      order?.orderDetailsByUser !== undefined &&
      order?.orderDetailsByUser !== null
    ) {
      const asArray = Object.entries(order?.orderDetailsByUser); // Convert `order` to a key/value array
      // Use `filter()` to filter the key/value array
      const orderDetailsUpdated = asArray.filter(
        ([key]) => key !== data.user_id
      );
      const orderDetailsUpdatedAsObj = Object.fromEntries(orderDetailsUpdated); // Convert the key/value array back to an object:
      setOrder({
        ...order,
        orderDetailsByUser: orderDetailsUpdatedAsObj
      } as API.Order);
    }
  });
  const orderDetailsByUser = Object.values(props.orderDetailsByUser);

  let none = "";

  if (Object.keys(props.orderDetailsByUser).length === 0) {
    none = "Test__none";
  } else {
    none = "Test";
  }

  return (
    <div className="SummaryTable">
      {/* Summary table with all properties */}
      <div className={`${none}`}>
        <Table bordered>
          <thead>
            <tr>
              <th>EMRI</th>
              <th>PRODUKTET</th>
              <th>TOTALI</th>
              <th>PARA TË GATSHME</th>
              <th>KUSURI</th>
              <th>EDITO</th>
            </tr>
          </thead>
          <tbody>
            {orderDetailsByUser.map((order, index) => {
              const isAdmin =
                order.full_name === authCtx.user?.full_name &&
                !props.orderExpired;
              return (
                <tr
                  className={cs("SummaryTable__active", {
                    SummaryTable__admin: isAdmin
                  })}
                  key={`OrderDetails-${index}`}
                >
                  <td>{order.full_name}</td>
                  <td>
                    <ul>
                      {order.products.map((product, index) => {
                        return (
                          <li key={`Product-${index}`}>
                            {product.quantity} x {product.product_name}
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td>{order.total}€</td>
                  <td>{order.payed_amount}€</td>
                  <td>{order.change}€</td>
                  <td className="SummaryTable__edit--align">
                    {isAdmin && (
                      <div className="SummaryTable__edit--wrapper">
                        <>
                          <Button
                            style={{ margin: "0 5px" }}
                            className="SummaryTable__action"
                            to={`/order-page/${props.categoryId}/${props.orderId}`}
                            icon="vector"
                          />
                          <HandleLoadingState loading={props.isDeleting}>
                            <Button
                              style={{ margin: "0 5px" }}
                              className="SummaryTable__action"
                              onClick={() => props.onDelete(props.orderId)}
                              icon="trash"
                            />
                          </HandleLoadingState>
                        </>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* For each order a specific table with all properties, this is used when app used in mobile */}
      {orderDetailsByUser.map((order, index) => (
        <>
          <Table className="table__responsive" key={index} bordered>
            <div className={`${none}`}>
              <tbody>
                <tr>
                  <th>EMRI</th>
                  <td>{order.full_name}</td>
                </tr>
                <tr>
                  <th>PRODUKTET</th>
                  <td>
                    <ul>
                      {order.products.map((products, index) => (
                        <li key={index}>
                          {products.quantity}x {products.product_name}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>TOTALI</th>
                  <td>{order.total}€</td>
                </tr>
                <tr>
                  <th>PARA TE GATSHME</th>
                  <td>{order.payed_amount}€</td>
                </tr>
                <tr>
                  <th>KUSURI</th>
                  <td>{order.change}€</td>
                </tr>
                {order.full_name === authCtx.user?.full_name &&
                  !props.orderExpired && (
                    <tr>
                      <th style={{ background: "#ff2509", color: "white" }}>
                        FSHIJE POROSINE
                      </th>
                      <td style={{ padding: "0px" }}>
                        <div className="SummaryTable__edit--wrapper">
                          <Button
                            className="SummaryTable__action"
                            to={`/order-page/${props.categoryId}/${props.orderId}`}
                            icon="vector"
                          />
                          <HandleLoadingState loading={props.isDeleting}>
                            <DeleteButton
                              className="SummaryTable__action"
                              onClick={() => props.onDelete(props.categoryId)}
                            />
                          </HandleLoadingState>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </div>
          </Table>
          {order.full_name === authCtx.user?.full_name && !props.orderExpired && (
            <button
              onClick={() => {
                confirmation.confirm("Mbyll porosinë?").then(formik.submitForm);
              }}
              className={`Summary__cancel Button Summary__button btn btn-secondary ${none}`}
            >
              ANULO POROSINË <img src={Vector} alt={Vector} />
            </button>
          )}
        </>
      ))}

      {Object.keys(props.orderDetailsByUser).length === 0 && (
        <div className="SummaryTable__edit--wrapperNone">
          <BoxItem
            icon="add-order"
            type="L"
            isStatic={true}
            itemDescription="SHTO POROSI TË RE"
            path={`/order-page/${props.categoryId}/${props.orderId}`}
          />
        </div>
      )}
    </div>
  );
};

// props.onDelete(props.orderId)
