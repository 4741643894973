import React from "react"
import { Button, ButtonProps } from "reactstrap"

import "./BillButton.scss"

interface Props extends ButtonProps {}

export const BillButton = (props: Props) => {
  return (
    <div className="BillButton">
      <Button {...props} className="BillButton__body">
        • • •
      </Button>
    </div>
  )
}
