import React from "react"
import { ResetAccountLinkForm } from "../../components/User/ResetAccountLinkForm/ResetAccountLinkForm"
import { Animate } from "../../components/shared/Animate/Animate"
import { FormBox } from "../../components/FormBox/FormBox"

//styles
import "./ResetAccountLink.scss"

export const ResetAccountLink = () => {
  return (
    <Animate animation="bottom-up">
      <div className="ResetAccountLink centered-content">
        <FormBox>
          <ResetAccountLinkForm />
        </FormBox>
      </div>
    </Animate>
  )
}
