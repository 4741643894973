import React from "react";
import { Form, Label, FormFeedback } from "reactstrap";

import { useErrorHandler } from "../../../lib/hooks/useErrorHandler";

import { CategoriesFormik } from "../../../pages/Categories/lib/useCategoriesFormik";

import * as API from "../../../api/Api";
import { Icon } from "../../../components/shared/Icon/Icon";
import { Button } from "../../../components/shared/Button/Button";
import { MinutesSelect } from "../../MinutesSelect/MinutesSelect";
import { BoxItem } from "../../../components/shared/BoxItem/BoxItem";
import { FormGroup } from "../../../components/shared/FormGroup/FormGroup";
import { ErrorMessage } from "../../../components/shared/ErrorMessage/ErrorMessage";
import { Input } from "../../shared/Input/Input";

import "./AddOrderForm.scss";

interface Props {
  formik: CategoriesFormik;
  categories: API.Category[];
}

export const AddOrderForm = (props: Props) => {
  const { formik, categories } = props;
  const errorHandler = useErrorHandler();

  return (
    <div className="AddOrderForm">
      <Form onSubmit={formik.handleSubmit}>
        <div className="AddOrderForm__row">
          <FormGroup>
            <Input
              type="text"
              id="orderName"
              name="orderName"
              placeholder="Emri i Porosisë"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.orderName}
              invalid={!!formik.errors.orderName && formik.touched.orderName}
              isInvalid={!!formik.errors.orderName && formik.touched.orderName}
            />
            <FormFeedback>{formik.errors.orderName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            {/* <Label for="deadline">Kohëzgjatja:</Label> */}
            <MinutesSelect
              fieldName="deadline"
              value={formik.values.expiresIn}
              onChange={(value) => {
                formik.setFieldValue("expiresIn", value);
                formik.setFieldTouched("expiresIn");
              }}
            />
          </FormGroup>
        </div>

        <div className="AddOrderForm__menu">
          <FormGroup>
            <Label for="deadline">Lokacioni:</Label>
            <div className="AddOrderForm__options">
              {categories.map((category) => (
                <BoxItem
                  type="S"
                  id={formik.values.categoryId}
                  key={category.id}
                  isProduct={false}
                  picturePath={category.media}
                  itemDescription={category.name}
                  onClick={() => {
                    formik.setFieldValue("categoryId", category.id);
                    formik.setFieldValue("categoryName", category.name);
                  }}
                  isActive={formik.values.categoryId === category.id}
                />
              ))}
            </div>

            <div className="AddOrderForm__title-error">
              {!!formik.errors.categoryId &&
                formik.touched.categoryId &&
                formik.errors.categoryId}
            </div>
          </FormGroup>
        </div>
        <ErrorMessage errorData={errorHandler} onClose={errorHandler.reset} />
      </Form>
      <Button
        type="submit"
        onClick={formik.submitForm}
        className="AddOrderForm__button"
        disabled={formik.isSubmitting || (!formik.dirty && formik.isValid)}
      >
        <span>{formik.isSubmitting ? "Porosia po krijohet..." : "VAZHDO"}</span>
        <Icon className="AddOrderForm__button-icon" icon="arrow" />
      </Button>
    </div>
  );
};
