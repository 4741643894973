import React, { useState } from "react";
import { Button } from "../../../components/shared/Button/Button";
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { Input } from "../../shared/Input/Input";
import { useSetAccountFormik } from "./lib/useSetAccountFormik";
import { Alert } from "../../shared/Alert/Alert";
import { NavLink } from "react-router-dom";
import * as API from "../../../api/Api";

//styles
import "./SetAccountForm.scss";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useRouter } from "../../../lib/hooks/useRouter";

interface Props {
  token: string;
}
const SetAccountForm = (props: Props) => {
  const errorHandler = useErrorHandler();
  const [alert, setAlert] = useState<boolean>(false);
  const router = useRouter();
  const authCtx = useAuthContext();
  const { token } = props;

  const formik = useSetAccountFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.setAccount(values, props.token);
        authCtx.login(res);
        router.history.push("/");
        formikHelpers.resetForm();
      } catch (e: any) {
        errorHandler.handleError(e);
      }
    }
  });

  return (
    <div className="SetAccountForm">
      <form onSubmit={formik.handleSubmit}>
        <h5 className="SetAccountForm__title">Set Your Account</h5>
        <Input
          placeholder="Write your full name"
          type="text"
          name="full_name"
          id="full_name"
          value={formik.values.full_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.full_name && formik.touched.full_name}
          error={formik.errors.full_name}
        />

        <Input
          placeholder="Write your password"
          type="password"
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.password && formik.touched.password}
          isInvalid={!!formik.errors.password && formik.touched.password}
          error={formik.errors.password}
        />
        <Input
          placeholder="Write your password confirmation"
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={
            !!formik.errors.confirmPassword && formik.touched.confirmPassword
          }
          isInvalid={
            !!formik.errors.confirmPassword && formik.touched.confirmPassword
          }
          error={formik.errors.confirmPassword}
        />
        <Alert
          duration={2}
          color="success"
          status={alert}
          onClose={() => setAlert(false)}
        >
          Account successfully registered!
        </Alert>
        {errorHandler.error === "jwt expired" ? (
          <Alert status={true} onClose={errorHandler.reset} color="danger">
            Token has expired!
            <NavLink
              className="SetPassword__resend-email"
              to={`/reset-account-link/${token}`}
            >
              {" "}
              Resend your email?
            </NavLink>
          </Alert>
        ) : (
          <ErrorMessage onClose={errorHandler.reset} errorData={errorHandler} />
        )}

        <Button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? "SAVING..." : "SAVE"}
        </Button>
      </form>
    </div>
  );
};

export default SetAccountForm;
