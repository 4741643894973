import React from "react"
import Skeleton from "react-loading-skeleton"

import "./TotalSummarySkeleton.scss"

const TotalSummarySkeleton = () => {
  return (
    <div className="TotalSummarySkeleton">
      <div className="TotalSummarySkeleton__wrapper">
        <table className="TotalSummarySkeleton__content">
          <thead>
            <tr>
              <th>
                <Skeleton width={100} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton duration={2} width={40} />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton duration={2} width={40} />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton duration={2} width={40} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TotalSummarySkeleton
