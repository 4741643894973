import io from "socket.io-client"
import React, { useContext } from "react"

export const socket = io("https://api.kutia-porosia.k-clients.com")

export interface SocketContextType {
  socket: SocketIOClient.Socket
}

const defaultSocketContext: SocketContextType = {
  socket: {} as SocketIOClient.Socket
}

export const SocketContext = React.createContext<SocketContextType>(
  defaultSocketContext
)

export function useSocketContext() {
  return useContext(SocketContext)
}
