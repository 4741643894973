import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"
import { ForgotPasswordField } from "../ForgotPasswordForm"

interface useForgotPasswordFormOption {
  initialValues?: ForgotPasswordField
  onSubmit: (
    value: ForgotPasswordField,
    formikHelpers: FormikHelpers<ForgotPasswordField>
  ) => Promise<any>
}

export const useForgotPasswordFormik = (
  options: useForgotPasswordFormOption
) => {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required")
  })

  return useFormik({
    initialValues: {
      email: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type ForgotPasswordFormik = ReturnType<typeof useForgotPasswordFormik>
