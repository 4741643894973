import React from "react"
import { Spinner } from "reactstrap"

interface Props {
  loading?: boolean
  children: JSX.Element
  loaderClassName?: string
  placeholder?: JSX.Element
}

export const HandleLoadingState = (props: Props) => {
  if (!props.loading) {
    return <>{props.children}</>
  }

  return props.placeholder ? (
    props.placeholder
  ) : (
    <Spinner className={`Loader ${props.loaderClassName}`} />
  )
}
