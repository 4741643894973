import { apiRequest } from "./Api";
import { IconDefinition } from "../components/shared/Icon/Icon.generated";

export interface Category {
  id: string;
  name: string;
  media: IconDefinition;
}
export interface CategoriesResponse {
  category: Category[];
}

export const getCategories = async () =>
  apiRequest<undefined, CategoriesResponse>(
    "get",
    "dashboard/category/get-all"
  );
