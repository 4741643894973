export const getIsoDate = (minutesToAdd: number) => {
  const currentDate = new Date();
  const currentMinutes = currentDate.getMinutes();
  const timer = currentDate.setMinutes(currentMinutes + minutesToAdd);
  const date = new Date(timer);
  const isoDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
  return isoDate;
};
