import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import * as API from "../../api/Api";
import { useCategoriesFormik } from "./lib/useCategoriesFormik";
import { useErrorHandler } from "../../lib/hooks/useErrorHandler";
import { useSocketContext } from "../../lib/context/SocketContext/SocketContext";

//components
import { AddOrderLoader } from "./AddOrderLoader";
import { BackButton } from "../../components/shared/BackButton/BackButton";
import { AddOrderForm } from "../../components/AddOrder/AddOrderForm/AddOrderForm";
import { HandleRequestState } from "../../components/shared/HandleRequestState/HandleRequestState";

//styles
import "./AddOrder.scss";
import { getIsoDate } from "../../lib/helpers/getIsoDate";

interface Props {
  buttonLabel?: string;
  className?: string;
}

interface CategoryId {
  categoryId?: string;
}

const currentDate = new Date();

export const AddOrder = (props: RouteComponentProps<Props>) => {
  const errorHandler = useErrorHandler();
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<API.Category[]>([]);

  const { socket } = useSocketContext();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    socket.on("createCategory", (data: API.Category) => {
      setCategories((prevState) => [...prevState, data]);
    });
  }, []);

  useEffect(() => {
    socket.on("deleteCategory", (data: CategoryId) => {
      setCategories((prevState) =>
        prevState.filter((category) => category.id !== data.categoryId)
      );
    });
  }, []);

  const formik = useCategoriesFormik({
    onSubmit: async (values, formikHelpers) => {
      const endTime = getIsoDate(values.expiresIn);
      try {
        const res = await API.createOrder({
          name: values.orderName,
          title: values.categoryName,
          deadline: endTime
        });

        props.history.push(`/order-page/${values.categoryId}/${res.order.id}`);

        formikHelpers.resetForm();
      } catch (e: any) {
        errorHandler.handleError(e);
      }
    }
  });

  const getCategories = async () => {
    try {
      const categories = await API.getCategories();
      setCategories(categories.category);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="AddOrder">
      <HandleRequestState
        state={loading}
        placeholder={<p className="text-primary">Loading...</p>}
      >
        <BackButton className="AddOrder__backbutton" to="/main-page">
          Back
        </BackButton>
        <AddOrderForm categories={categories} formik={formik} />
      </HandleRequestState>
    </div>
  );
};
