import React from "react"
import { NavLink } from "react-router-dom"
import { RegisterForm } from "../../components/User/RegisterForm/RegisterForm"
import { Animate } from "../../components/shared/Animate/Animate"
import { FormBox } from "../../components/FormBox/FormBox"
//styles
import "./Register.scss"

export const Register = () => {
  return (
    <Animate animation="bottom-up">
      <div className="Register centered-content">
        <FormBox>
          <RegisterForm />
          <NavLink className="Register__nav" to="/login">
            Already have an account? Log In
          </NavLink>
        </FormBox>
      </div>
    </Animate>
  )
}
