import React from "react"
import { BoxItemLoader } from "../../components/shared/BoxItem/BoxItemLoader"

export const OrderPageItemLoader = () => {
  return (
    <div>
      <>
        <BoxItemLoader
          width={259}
          height={259}
          category={false}
          itemSize="45"
          desc={true}
        />
        <BoxItemLoader
          width={259}
          height={259}
          category={false}
          itemSize="45"
          desc={true}
        />
        <BoxItemLoader
          width={259}
          height={259}
          category={false}
          itemSize="45"
          desc={true}
        />
      </>
    </div>
  )
}
