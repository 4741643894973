import React, { useCallback, useEffect, useState } from "react";

import { Animate } from "../../components/shared/Animate/Animate";
import { BackButton } from "../../components/shared/BackButton/BackButton";
import { BoxItemLoader } from "../../components/shared/BoxItem/BoxItemLoader";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { useErrorHandler } from "../../lib/hooks/useErrorHandler";
import { useRouter } from "../../lib/hooks/useRouter";
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage";
import { Alert } from "../../components/shared/Alert/Alert";
import { Order } from "../../components/Order/Order";
import * as API from "../../api/Api";

import "./OrderArchive.scss";

export const OrderArchive = () => {
  const [inActiveOrders, setInActiveOrders] = useState<API.Order[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const errorHandler = useErrorHandler();

  const getInactiveOrders = useCallback(async () => {
    try {
      const allOrders = await API.getAllOrders();
      setInActiveOrders(allOrders.inActiveOrders);
    } catch (e: any) {
      errorHandler.handleError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getInactiveOrders();
  }, [getInactiveOrders]);

  return (
    <div className="ActiveList">
      <Animate animation="bottom-up">
        <>
          <div className="ActiveList__header">
            <BackButton className="ActiveList__backbutton" to="active-list">
              Back
            </BackButton>

            {errorHandler && (
              <ErrorMessage errorData={errorHandler}></ErrorMessage>
            )}
          </div>
          <div className="ActiveList__container">
            <HandleLoadingState
              loading={loading}
              placeholder={<ActiveListPlaceholder />}
            >
              <>
                {!inActiveOrders || inActiveOrders.length === 0 ? (
                  <Alert className="ActiveList__alert" color="info">
                    Nuk ka porosi ne arkivë!{" "}
                  </Alert>
                ) : (
                  inActiveOrders.map((item) => {
                    return (
                      <Order
                        key={`Order-${item.id}`}
                        id={item.id}
                        category={item.category}
                        user={item.name}
                        orderAuthor={item.order_author}
                        userList={item.order_customers}
                        expireTime={item.deadline}
                        onClick={() => {
                          router.history.push(`/summary/${item.id}`);
                        }}
                      />
                    );
                  })
                )}
              </>
            </HandleLoadingState>
          </div>
        </>
      </Animate>
    </div>
  );
};

function ActiveListPlaceholder() {
  return (
    <>
      <BoxItemLoader
        width={369}
        height={369}
        title={true}
        itemSize="25"
        category={true}
      />
      <BoxItemLoader
        width={369}
        height={369}
        title={true}
        itemSize="25"
        category={true}
      />
      <BoxItemLoader
        width={369}
        height={369}
        title={true}
        itemSize="25"
        category={true}
      />
    </>
  );
}
