import React from "react"
import { Icon } from "../shared/Icon/Icon"
import { ActiveUsers } from "../ActiveUser/ActiveUsers"
import { getIndicator } from "../../lib/helpers/getIndicator"
import { DeleteButton } from "../shared/DeleteButton/DeleteButton"
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext"
import { useTimeLeft } from "../../lib/hooks/useTimeLeft"
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState"

import "./Order.scss"

interface Props {
  id: string
  category?: string
  user?: string
  expireTime: string
  onClick?: () => void
  userList?: string[]
  orderAuthor?: string
  deleting?: boolean
  deleteOrderId?: string
  handleDelete?: (orderId: string) => void
}

export const Order = (props: Props) => {
  const timeLeft = useTimeLeft(props.expireTime)
  const indicator = getIndicator(timeLeft)
  const authCtx = useAuthContext()

  const icon =
    props.category === "KAFE"
      ? "coffee"
      : props.category === "PAUZA"
      ? "pause"
      : "market"

  return (
    <div className="Order" onClick={props.onClick}>
      {props.handleDelete && authCtx.user?.full_name === props.orderAuthor && (
        <HandleLoadingState
          loaderClassName="Order__loader"
          loading={props.deleting && props.deleteOrderId === props.id}
        >
          <DeleteButton
            className="Order__delete"
            onClick={(e: MouseEvent) => {
              e.stopPropagation()
              props.handleDelete && props.handleDelete(props.id)
            }}
          />
        </HandleLoadingState>
      )}
      <div className="Order__order-title">
        <p>{props.user}</p>
        <div className="Order__expireTime">
          {timeLeft.hours > 0 && timeLeft.hours + "h"}{" "}
          {timeLeft.expired ? "expired" : timeLeft.minutes + "min"}
          <div className={`Order__expireTime-${indicator}`}></div>
        </div>
      </div>
      <div className="Order__icon">
        <Icon icon={icon} />
      </div>
      <div className="Order__title">
        <p>{props.category}</p>
      </div>

      <div className="Order__usersList">
        <ActiveUsers users={props.userList} />
      </div>
    </div>
  )
}
