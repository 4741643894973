import React from "react";
import DragAndDrop from "../../shared/DragAndDrop/DragAndDrop";
import { useAddProductFormik } from "./lib/useAddProductFormik";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "../../../components/shared/Button/Button";
import { Modal } from "../../../components/shared/Modal/Modal";
import { Input } from "../../shared/Input/Input";
import { ErrorMessage } from "../../../components/shared/ErrorMessage/ErrorMessage";
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler";
import * as API from "../../../api/Api";

//styles
import "./AddProductModal.scss";

interface Props {
  isOpen: boolean;
  fade: boolean;
  toggle: () => void;
  categoryId: string;
}

export interface AddProductFields {
  selectedPhoto: any;
  productName: string;
  productPrice: string;
}

export const AddProductModal = (props: Props) => {
  const { categoryId } = props;
  const errorHandler = useErrorHandler();

  const formik = useAddProductFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        let formData = new FormData();
        formData.append("name", values.productName);
        formData.append("price", values.productPrice);
        formData.append("image", values.selectedPhoto);
        formData.append("categoryId", categoryId);
        const res = await API.createProduct(formData);
      } catch (e: any) {
        errorHandler.handleError(e);
      } finally {
        formikHelpers.resetForm();
        props.toggle();
      }
    }
  });

  const handlePhotoSelection = (photoFile: File) => {
    formik.setFieldValue("selectedPhoto", photoFile);
  };

  return (
    <Modal
      className="AddProductModal"
      isOpen={props.isOpen}
      fade={props.fade}
      toggle={props.toggle}
      centered
    >
      <form
        className="AddProductModal__container"
        onSubmit={formik.handleSubmit}
      >
        <ModalHeader
          className="AddProductModal__header"
          toggle={props.toggle}
        />
        <ModalBody className="AddProductModal__body">
          <DragAndDrop onPhotoSelect={handlePhotoSelection} />

          {!!formik.errors.selectedPhoto && formik.touched.selectedPhoto && (
            <div className="AddProductModal__selectedPhoto-error">
              {formik.errors.selectedPhoto}
            </div>
          )}
          <div className="AddProductModal__form">
            <Input
              placeholder="Emri i Produktit"
              type="text"
              name="productName"
              id="productName"
              value={formik.values.productName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                !!formik.errors.productName && formik.touched.productName
              }
              isInvalid={
                !!formik.errors.productName && formik.touched.productName
              }
              error={formik.errors.productName}
            />
            <Input
              placeholder="Cmimi i Produktit"
              type="number"
              name="productPrice"
              id="productPrice"
              value={formik.values.productPrice}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                !!formik.errors.productPrice && formik.touched.productPrice
              }
              isInvalid={
                !!formik.errors.productPrice && formik.touched.productPrice
              }
              error={formik.errors.productPrice}
            />
          </div>
        </ModalBody>

        <ModalFooter className="AddProductModal__footer">
          <ErrorMessage errorData={errorHandler} onClose={errorHandler.reset} />

          <Button
            className="AddProductModal__button"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Submitting..." : "Done"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
