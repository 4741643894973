import { FormikHelpers, useFormik } from "formik"
import * as Yup from "yup"
import { AddProductFields } from "../AddProductModal"

interface UseAddProductOptions {
  initialValues?: AddProductFields
  onSubmit: (
    values: AddProductFields,
    formikHelpers: FormikHelpers<AddProductFields>
  ) => Promise<any>
}

export const useAddProductFormik = (options: UseAddProductOptions) => {
  const AddProductSchema = Yup.object().shape({
    productName: Yup.string().required("Product name is required"),
    productPrice: Yup.string().required("Product price is required")
  })

  return useFormik({
    initialValues: {
      selectedPhoto: [],
      productName: "",
      productPrice: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddProductSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type AddProductFormik = ReturnType<typeof useAddProductFormik>
